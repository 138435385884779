import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { GamePlayer, GameRoomFull, OP_CODE, OperationBody, RoomState } from '@repo/types';
import RoomService from './RoomService';
import { useNavigate, useLocation } from 'react-router-dom';
import { RTMessage } from '@repo/gamelift-client';
import GameLiftService from './GameLiftService';

type RoomContextType = {
    room?: GameRoomFull;
    leaveRoom: () => Promise<void>;
    joinRoom: (roomId: string) => Promise<void>;
    currentPlayer?: GamePlayer;
    otherPlayers?: (GamePlayer | undefined)[];
};

type RoomProviderProps = {
    children: ReactNode;
};

const RoomContext = createContext<RoomContextType | null>(null);

export const useRoom = () => {
    const context = useContext(RoomContext);
    if (!context) {
        throw new Error('useRoom must be inside a RoomProvider');
    }
    return context;
};

export const RoomProvider: FC<RoomProviderProps> = ({ children }) => {
    const [roomData, setRoomData] = useState<GameRoomFull | undefined>(undefined);
    const [currentPlayer, setCurrentPlayer] = useState<GamePlayer | undefined>(undefined);
    const [otherPlayers, setOtherPlayers] = useState<(GamePlayer | undefined)[]>(new Array(3).fill(undefined));
    const navigate = useNavigate();
    const location = useLocation();

    const joinRoom = async (id: string) => {
        try {
            await RoomService.joinRoom(id);
        } catch (error) {
            console.error(`Failed to join room ${id}:`, error);
        }
    };

    const leaveRoom = async () => {
        try {
            await RoomService.leaveRoom();
        } catch (error) {
            console.error('Failed to leave room:', error);
        }
    };

    const handleRoomUpdate = useCallback(
        (message: RTMessage<OperationBody[OP_CODE.CURRENT_ROOM_UPDATE]>) => {
            const previousRoomState = roomData?.state;

            const room = message.getPayloadObject();
            setRoomData(room);

            const currentPlayer = room.players.find((p) => p.current_player);
            setCurrentPlayer(currentPlayer);

            if (currentPlayer) {
                const players: (GamePlayer | undefined)[] = room?.players.filter((p) => p.id !== currentPlayer.id);
                for (let i = 0; i < 3; i++) {
                    players[i] ??= undefined;
                }
                setOtherPlayers(players);
            }

            if (previousRoomState !== room.state) {
                const env = localStorage.getItem('semsom_env');
                switch (room.state) {
                    case RoomState.CHARACTER_SELECT:
                        if (env && location.pathname.includes('result')) { //if we are a test, don't go to character select as the test may take more time than the timeout.
                            return;
                        } else {
                            navigate('/character-select?room=' + room.id);
                        }
                        break;
                    case RoomState.GAME:
                        navigate('/game?room=' + room.id);
                        break;
                    case RoomState.RESULTS:
                        navigate('/result?room=' + room.id);
                        break;
                }
            }
        },
        [navigate, roomData?.state],
    );

    const handleGameOver = useCallback(
        (data: RTMessage<OperationBody[OP_CODE.GAME_OVER]>) => {
            setRoomData({
                ...roomData!,
                state: RoomState.GAME_OVER,
                players: data.getPayloadObject().players,
            });
        },
        [roomData],
    );

    const handleLeaveRoom = useCallback(() => {
        setRoomData(undefined);
        setCurrentPlayer(undefined);
        navigate('/room-select');
    }, [navigate]);

    useEffect(() => {
        GameLiftService.onMessage[OP_CODE.CURRENT_ROOM_UPDATE].subscribe(handleRoomUpdate);
        GameLiftService.onMessage[OP_CODE.GAME_OVER].subscribe(handleGameOver);
        GameLiftService.onMessage[OP_CODE.LEAVE_ROOM].subscribe(handleLeaveRoom);

        return () => {
            GameLiftService.onMessage[OP_CODE.CURRENT_ROOM_UPDATE].unsubscribe(handleRoomUpdate);
            GameLiftService.onMessage[OP_CODE.GAME_OVER].unsubscribe(handleGameOver);
            GameLiftService.onMessage[OP_CODE.LEAVE_ROOM].unsubscribe(handleLeaveRoom);
        };
    });

    return (
        <RoomContext.Provider value={{ room: roomData, joinRoom, currentPlayer, leaveRoom, otherPlayers }}>{children}</RoomContext.Provider>
    );
};
