import { useEffect } from 'react';
import { useAuth } from '../../../shared/services/AuthContext';
import { useNavigate } from 'react-router-dom';
import { LoaderOverlay } from '../../../shared/components/LoaderOverlay';

// this should trigger the GameLift client to initialise
import '../../../shared/services/GameLiftService';

export const Start = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const login = async () => {
        const loggedIn = await auth.login();
        if (loggedIn) {
            navigate('/room-select');
        }
    };

    useEffect(() => {
        login().then();
    });

    return <LoaderOverlay />;
};
