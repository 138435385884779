import { useCallback, useEffect, useState } from 'react';
import { GameView } from '../shared/components/GameView';
import { TwoColumnGrid } from '../../../shared/components/TwoColumnGrid';
import { Keyboard } from '../shared/components/Keyboard';
import { Question } from '../shared/types/Question';
import { useGame } from '../../../shared/services/GameContext';
import { LoadBar } from '../../../shared/components/LoadBar';
import { WoodenBackground } from '../../../shared/components/WoodenBackground';
import GameLiftService from '../../../shared/services/GameLiftService';
import { OP_CODE, PRESENT_TYPE, PresentEffect, RoomState } from '@repo/types';
import Questions from '../../../shared/data/questions';
import { RTMessage } from '@repo/gamelift-client';
import { useRoom } from '../../../shared/services/RoomContext';
import { Config, sleep } from '@repo/common';
import { presentImages, SoundKey } from '../../../shared/data';
import { cn } from '../../../shared/utils';

import PresentShine from '../../../shared/assets/img/present-shine.png';
import GrapesOverlay from '../../../shared/assets/img/grapes-overlay.webp';
import AudioService from '../../../shared/services/AudioService';
import { LeaveRoomButton } from '../../../shared/components/LeaveRoomButton';

export const Game = () => {
    const { animateMoveScreenView } = useGame();
    const { currentPlayer, room, leaveRoom } = useRoom();

    const [countDown, setCountDown] = useState<number>((!room ? false : Date.now() > new Date(room!.starts_at).getTime() + 5000) ? 0 : 3);
    const [questions] = useState<Question[]>(Questions);

    const [currentQuestion, setCurrentQuestion] = useState<Question | null>(questions[Math.floor(Math.random() * questions.length)]);
    const [givenAnswer, setGivenAnswer] = useState<string>('');

    const [displayPresent, setDisplayPresent] = useState<boolean>(false);
    const [lastPresent, setLastPresent] = useState<PRESENT_TYPE | undefined>(undefined);
    const [showGrapesOverlay, setShowGrapesOverlay] = useState<boolean>(false);
    const [playerPresentEffects, setPlayerPresentEffects] = useState<Record<string, PRESENT_TYPE>>({});
    const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
    const [presentLocations, setPresentLocations] = useState<number[]>(Config.PRESENT_LOCATIONS);
    const [blurQuestion, setBlurQuestion] = useState<number>(0);

    // The percent of the entire track the player can move with the max step
    const maxMovePercent = 7.5;
    const timePerQuestion = 10_000;

    // The actual percent the player is going to move of the max
    const [currentMovePercent, setCurrentMovePercent] = useState<number>(100);
    const [currentMovePercentInterval, setCurrentMovePercentInterval] = useState<ReturnType<typeof setInterval> | undefined>(undefined);

    const [previousAnswers, setPreviousAnswers] = useState<
        {
            id: string;
            questionText: string;
            givenAnswer: string;
            isCorrect: boolean;
        }[]
    >([]);

    const [isMoving, setIsMoving] = useState<number>(0);

    const startCurrentMovePercentInterval = useCallback(() => {
        if (currentMovePercentInterval !== undefined) {
            clearInterval(currentMovePercentInterval);
        }

        setCurrentMovePercent(100);
        const intervalId = setInterval(() => {
            setCurrentMovePercent((prev) => {
                const newPercent = Math.max(prev - 1, 0);
                if (newPercent === 0) {
                    clearInterval(intervalId);
                    setCurrentMovePercentInterval(undefined);
                }
                return newPercent;
            });
        }, timePerQuestion / 100);

        setCurrentMovePercentInterval(intervalId);
    }, [currentMovePercentInterval, timePerQuestion]);

    useEffect(() => {
        if (countDown > 0) {
            const timeoutId = setTimeout(() => {
                setCountDown(countDown - 1);

                if (countDown === 1) {
                    startCurrentMovePercentInterval();
                }
            }, 1000);

            return () => clearTimeout(timeoutId);
        }

        animateMoveScreenView();
    }, [countDown, animateMoveScreenView, startCurrentMovePercentInterval]);

    useEffect(() => {
        return () => {
            if (currentMovePercentInterval !== undefined) {
                clearInterval(currentMovePercentInterval);
            }
        };
    }, [currentMovePercentInterval]);

    const handleInput = async (value: string) => {
        if (!currentQuestion?.answer) return;

        if (value === '<') {
            setGivenAnswer(givenAnswer.slice(0, -1));
            return;
        }

        const regex = /^[0-9]$/;
        if (!regex.test(value) && value !== '') {
            return;
        }

        const newValue = (givenAnswer + value).slice(0, currentQuestion.answer.toString().length);
        setGivenAnswer(newValue);

        if (newValue.length === currentQuestion.answer.toString().length) {
            await checkAnswer(newValue);
        }
    };

    const getNextQuestion = (previousQuestion: Question) => {
        const options = questions.filter((q) => q.questionText !== previousQuestion.questionText);
        return options[Math.floor(Math.random() * options.length)];
    };

    const checkAnswer = async (answer: string) => {
        const isCorrect = currentQuestion!.answer === +answer;

        console.log(`answer checked: ${answer} is ${isCorrect ? 'correct' : 'incorrect'}`);
        const lastAnswer = previousAnswers.at(-1);
        if (
            isCorrect ||
            !previousAnswers.length ||
            !(lastAnswer!.givenAnswer === answer && lastAnswer!.questionText === currentQuestion!.questionText)
        ) {
            setPreviousAnswers((prev) =>
                [
                    ...prev,
                    {
                        id: crypto.randomUUID(),
                        questionText: currentQuestion!.questionText!,
                        givenAnswer: answer,
                        isCorrect,
                    },
                ].slice(-5),
            );
        }

        if (!isCorrect) {
            AudioService.play(SoundKey.QUESTION_WRONG);
            return;
        }

        setGivenAnswer('');
        AudioService.play(SoundKey.QUESTION_CORRECT);

        console.log(`moving player with ${currentMovePercent}% of max move`);

        const hasCollectedPresent = await move((maxMovePercent / 100) * currentMovePercent);
        if (hasCollectedPresent) {
            await sleep(250);
        }

        setBlurQuestion((prev) => prev + 1);
        setCurrentQuestion(getNextQuestion(currentQuestion!));
        setTimeout(() => setBlurQuestion((prev) => prev - 1), 250);

        startCurrentMovePercentInterval();
    };

    const collectPresent = async () => {
        const client = await GameLiftService.client;
        client.send(client.newMessage(OP_CODE.GAME_COLLECT_PRESENT, {}));
        AudioService.play(SoundKey.PRESENT_COLLECT);
    };

    /**
     * @param distance The distance to move
     * @returns Whether the player has collected a present or won
     */
    const move = useCallback(
        async (distance: number) => {
            console.log(`attempting to move player by distance: ${distance}`);

            setIsMoving((prev) => prev + 1);

            const client = await GameLiftService.client;
            const newPlayerLocation = currentPlayer!.position + distance;

            console.log(`updating player location to: ${newPlayerLocation}`);

            client.send(
                client.newMessage(OP_CODE.GAME_POSITION_UPDATE, {
                    position: newPlayerLocation,
                }),
            );

            AudioService.play(SoundKey.MOVE);

            setTimeout(() => setIsMoving((prev) => Math.max(prev - 1, 0)), 500);

            // No more than one present can be collected at once
            if (presentLocations.some((presentLocation) => newPlayerLocation >= presentLocation)) {
                await collectPresent();
                setPresentLocations(presentLocations.filter((presentLocation) => presentLocation > newPlayerLocation));
                return true;
            }

            // Return if player has won
            return newPlayerLocation >= 100;
        },
        [currentPlayer, presentLocations],
    );

    const handlePresentEffect = useCallback(
        async (message: RTMessage<PresentEffect>) => {
            const presentEffect: PresentEffect = message.getPayloadObject();
            const isSender = presentEffect.sender_player_id === currentPlayer!.id;
            const isTarget = presentEffect.target_player_id === currentPlayer!.id;

            setPlayerPresentEffects({
                ...playerPresentEffects,
                [presentEffect.target_player_id]: presentEffect.present_type,
            });

            setTimeout(() => {
                setPlayerPresentEffects(
                    Object.entries(playerPresentEffects)
                        .filter(([key]) => key !== presentEffect.target_player_id)
                        .reduce(
                            (acc, [key, value]) => ({
                                ...acc,
                                [key]: value,
                            }),
                            {},
                        ),
                );
            }, Config.PRESENT_EFFECT_OVERLAY_DURATIONS[presentEffect.present_type]);

            if (isSender) {
                setBlurQuestion((prev) => prev + 1);
                setDisplayPresent(true);

                setLastPresent(presentEffect.present_type);
                await sleep(Config.PRESENT_DISPLAY_TIME);
                setDisplayPresent(false);

                setTimeout(() => setBlurQuestion((prev) => prev - 1), 750);
            }

            if (isTarget) {
                switch (presentEffect.present_type) {
                    case PRESENT_TYPE.BERRIES: {
                        AudioService.play(SoundKey.PRESENT_BERRIES_APPLY);
                        await move(15);
                        break;
                    }

                    case PRESENT_TYPE.BANANA: {
                        AudioService.play(SoundKey.PRESENT_BANANA_APPLY);
                        setInputsDisabled(true);
                        await sleep(Config.PRESENT_EFFECT_OVERLAY_DURATIONS[PRESENT_TYPE.BANANA]);
                        setInputsDisabled(false);
                        break;
                    }

                    case PRESENT_TYPE.CITRUS: {
                        AudioService.play(SoundKey.PRESENT_CITRUS_APPLY);
                        break;
                    }

                    case PRESENT_TYPE.GRAPES: {
                        AudioService.play(SoundKey.PRESENT_GRAPES_APPLY);
                        setShowGrapesOverlay(true);
                        await sleep(Config.PRESENT_EFFECT_OVERLAY_DURATIONS[PRESENT_TYPE.GRAPES]);
                        setShowGrapesOverlay(false);
                        break;
                    }
                }
            }
        },
        [currentPlayer, playerPresentEffects, move],
    );

    useEffect(() => {
        GameLiftService.onMessage[OP_CODE.GAME_PRESENT_EFFECT].subscribe(handlePresentEffect);
        return () => GameLiftService.onMessage[OP_CODE.GAME_PRESENT_EFFECT].unsubscribe(handlePresentEffect);
    });

    return (
       <>
            <div
                className={cn(
                    'pointer-events-auto fixed left-0 top-0 z-50 grid size-full place-items-center bg-black/50 opacity-100 backdrop-blur-sm transition-all',
                    countDown === 0 && 'pointer-events-none opacity-0',
                )}
                data-testid="countdown"
            >
                <h1 className="text-9xl font-bold text-white">{countDown !== 0 && countDown}</h1>
            </div>

            <div
                className={cn(
                    'pointer-events-none fixed inset-0 z-40 grid size-full place-items-center opacity-0 transition-all',
                    showGrapesOverlay && 'pointer-events-auto opacity-100',
                )}
                data-testid="grapes-overlay"
            >
                <img src={GrapesOverlay} alt="grapes overlay" className="size-full object-contain" />
            </div>

            <div
                className={cn(
                    'bg-gradient-radial animate-fade-out pointer-events-none invisible fixed left-0 top-0 z-50 flex size-full items-center justify-center from-transparent to-black opacity-0 transition-all',
                    displayPresent && 'animate-fade-in pointer-events-auto select-none opacity-100',
                    lastPresent && 'visible',
                )}
                data-testid="present-display"
            >
                <div className="absolute aspect-square size-full scale-[2] opacity-50">
                    <img
                        src={PresentShine}
                        className="absolute size-full animate-[spin_5s_linear_infinite] overflow-hidden rounded-full"
                        alt=""
                    />
                </div>

                {displayPresent && (
                    <img
                        src={presentImages[lastPresent!]}
                        className={cn('animate-scale-in z-10 aspect-square w-1/2 min-w-32 max-w-64 delay-1000')}
                        alt={lastPresent!}
                    />
                )}
            </div>

            <div className="flex max-h-screen flex-col">
                <div className="h-[calc(100dvh-5rem)] lg:h-[calc(100dvh-10rem)]" data-testid="main-game-container">
                    <TwoColumnGrid leftColumnSize="50%" rightColumnSize="50%">
                        <GameView
                            distanceIndicator={{
                                max: maxMovePercent,
                                current: currentMovePercent,
                                visible: !inputsDisabled && isMoving <= 0 && !displayPresent,
                            }}
                            playerPresentEffects={playerPresentEffects}
                        />

                        <div className="relative flex h-full max-h-[50vh] items-center justify-center lg:max-h-full">
                            <LeaveRoomButton leaveRoom={leaveRoom} data-testid="leave-room-button" />

                            <div className="size-full">
                                <WoodenBackground />

                                <div className="flex size-full flex-col items-center justify-center" data-testid="game-content">
                                    {room?.state === RoomState.GAME_OVER ? (
                                        <div className="flex w-full flex-col items-center justify-center">
                                            <h1 className="text-9xl font-bold text-white" data-testid="game-over">
                                                {currentPlayer?.position === 100 ? (
                                                    <div className="flex flex-col gap-4">
                                                        <h1 className="text-6xl drop-shadow-solidGray">🎉</h1>
                                                        <h2 className="text-4xl font-semibold drop-shadow-solidGray">Gewonnen!</h2>
                                                        <h3 className="text-2xl drop-shadow-sm">Doe je een dansje?</h3>

                                                        <div className="mx-auto mt-4 h-8 w-96 rounded-lg bg-gray-800/90 shadow-solidGray" data-testid="win-load-bar">
                                                            <LoadBar className="bg-yellow-400" duration={Config.ROOM_GAME_OVER_WAIT_TIME} />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-col gap-4">
                                                        <h1 className="text-6xl drop-shadow-solidGray">👍</h1>
                                                        <h2 className="text-4xl font-semibold drop-shadow-solidGray">Goed geprobeerd!</h2>
                                                        <h3 className="text-2xl drop-shadow-sm">Volgende keer beter</h3>

                                                        <div className="mx-auto mt-4 h-8 w-96 rounded-lg bg-gray-800/90 shadow-solidGray" data-testid="lose-load-bar">
                                                            <LoadBar className="bg-yellow-400" duration={Config.ROOM_GAME_OVER_WAIT_TIME} />
                                                        </div>
                                                    </div>
                                                )}
                                            </h1>
                                        </div>
                                    ) : (
                                        <div className="flex size-full flex-col items-center justify-center">
                                            <div className="flex h-full flex-col items-center justify-end gap-2 py-4 font-semibold text-white/80 drop-shadow-solidGray lg:gap-4 lg:py-8" data-testid="previous-answers">
                                                {previousAnswers.length ? (
                                                    <>
                                                        {previousAnswers.map((answer, idx) => (
                                                            <div
                                                                key={answer.id}
                                                                className={cn(
                                                                    'opacity-0 transition-all',
                                                                    idx === previousAnswers.length - 1 &&
                                                                        'text-3xl opacity-100 lg:text-6xl',
                                                                    idx === previousAnswers.length - 2 && 'text-xl opacity-80 lg:text-5xl',
                                                                    idx === previousAnswers.length - 3 && 'opacity-60 lg:text-4xl',
                                                                    idx === previousAnswers.length - 4 && 'text-xs opacity-25 lg:text-3xl',
                                                                    idx === previousAnswers.length - 5 &&
                                                                        'hidden opacity-10 lg:block lg:text-xl',
                                                                )}
                                                                data-testid={`previous-answer-${idx}`}
                                                            >
                                                                {answer.questionText}&nbsp;
                                                                <span
                                                                    className={cn(
                                                                        'font-black',
                                                                        answer.isCorrect ? 'text-green-500' : 'text-red-500',
                                                                    )}
                                                                >
                                                                    {answer.givenAnswer}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </div>

                                            {currentQuestion ? (
                                                <div className="flex w-full flex-col items-center justify-center" data-testid="current-question">
                                                    <div className="flex items-center justify-center gap-5 border-4 border-black bg-white p-4 shadow-solidGray lg:p-8">
                                                        {currentQuestion.questionText ? (
                                                            <h1
                                                                className={cn(
                                                                    'text-6xl font-bold transition-all duration-700 lg:text-7xl',
                                                                    (blurQuestion > 0 || countDown) && 'blur-md duration-0 lg:blur-xl',
                                                                )}
                                                                data-testid="question-text"
                                                            >
                                                                {currentQuestion.questionText}
                                                            </h1>
                                                        ) : null}

                                                        {currentQuestion.url ? <img src={currentQuestion.url} alt="question" data-testid="question-image" /> : null}

                                                        <div className="flex items-center gap-2" data-testid="question-answer" data-testvalue={currentQuestion.answer}>
                                                            {currentQuestion.answer
                                                                .toString()
                                                                .split('')
                                                                .map((_, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="h-full text-6xl font-bold outline-none lg:text-7xl"
                                                                        style={{
                                                                            width: '1ch',
                                                                        }}
                                                                    >
                                                                        {givenAnswer[index] ?? <div className="opacity-0">0</div>}
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}

                                            <div className="size-full"></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </TwoColumnGrid>
                </div>

                <Keyboard
                    inputClicked={handleInput}
                    disabled={inputsDisabled || room?.state !== RoomState.GAME || displayPresent || blurQuestion > 0}
                    data-testid="keyboard"
                />
            </div>
        </>
    );
};
