import React from 'react';
import { cn } from '../utils';

interface TwoColumnGridProps {
    leftColumnSize?: string;
    rightColumnSize?: string;
    leftColumnSizeMobile?: string;
    rightColumnSizeMobile?: string;
    height?: string;
    children: React.ReactNode;
    className?: string;
}

export const TwoColumnGrid: React.FC<TwoColumnGridProps> = ({
    leftColumnSize = '1fr',
    rightColumnSize = '1fr',
    leftColumnSizeMobile = '1fr',
    rightColumnSizeMobile = '1fr',
    height = '100%',
    children,
    className,
}) => {
    const customGridCols = `custom-cols-${leftColumnSize.replace('%', 'p').replace('/', '')}-${rightColumnSize
        .replace('%', 'p')
        .replace('/', '')}`;

    const customStyles = `
    .${customGridCols} {
      display: grid;
      grid-template-rows: ${leftColumnSizeMobile ? leftColumnSizeMobile : leftColumnSize} ${rightColumnSizeMobile ? rightColumnSizeMobile : rightColumnSize};
    }
    @media (min-width: 1024px) {
      .${customGridCols} {
        grid-template-columns: ${leftColumnSize} ${rightColumnSize};
        grid-template-rows: auto;
      }
    }
  `;

    return (
        <>
            <style>{customStyles}</style>
            <section className={cn(customGridCols, className)} style={{ height }}>
                {children}
            </section>
        </>
    );
};
