"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginCommand = void 0;
const CoreCommands_pb_1 = require("../proto/CoreCommands_pb");
const RTMessage_1 = require("../classes/RTMessage");
const types_1 = require("@repo/types");
class LoginCommand extends RTMessage_1.RTMessage {
    playerSessionId;
    constructor(playerSessionId, sender, payload) {
        super(types_1.OP_CODE.LOGIN_REQUEST, sender, payload);
        this.playerSessionId = playerSessionId;
        // -1 is the server
        this.targetPlayer = -1;
    }
    toInnerPacket(packet) {
        const loginCommand = new CoreCommands_pb_1.LoginCommand();
        loginCommand.setPlayersessionid(this.playerSessionId);
        loginCommand.setClientversion(2);
        packet.setLogin(loginCommand);
        return packet;
    }
}
exports.LoginCommand = LoginCommand;
