import { Howl } from 'howler';

export enum SoundKey {
    CLICK,
    QUESTION_WRONG,
    QUESTION_CORRECT,

    PRESENT_COLLECT,
    PRESENT_BERRIES_APPLY,
    PRESENT_BANANA_APPLY,
    PRESENT_CITRUS_APPLY,
    PRESENT_GRAPES_APPLY,

    MOVE,

    MUSIC_BACKGROUND,
}

export const sounds: Record<SoundKey, Howl> = {
    [SoundKey.CLICK]: new Howl({
        src: '/sounds/click.mp3',
        preload: true,
    }),
    [SoundKey.QUESTION_CORRECT]: new Howl({
        src: `/sounds/correct.mp3`,
        preload: true,
    }),
    [SoundKey.QUESTION_WRONG]: new Howl({
        src: `/sounds/wrong.mp3`,
        preload: true,
    }),
    [SoundKey.PRESENT_COLLECT]: new Howl({
        src: `/sounds/present-collect.mp3`,
        preload: true,
    }),
    [SoundKey.PRESENT_BERRIES_APPLY]: new Howl({
        src: `/sounds/presents/berries.mp3`,
        preload: true,
    }),
    [SoundKey.PRESENT_BANANA_APPLY]: new Howl({
        src: `/sounds/presents/banana.mp3`,
        preload: true,
    }),
    [SoundKey.PRESENT_CITRUS_APPLY]: new Howl({
        src: `/sounds/presents/citrus.mp3`,
        preload: true,
    }),
    [SoundKey.PRESENT_GRAPES_APPLY]: new Howl({
        src: `/sounds/presents/grapes.mp3`,
        preload: true,
    }),
    [SoundKey.MOVE]: new Howl({
        src: `/sounds/move.mp3`,
        preload: true,
    }),
    [SoundKey.MUSIC_BACKGROUND]: new Howl({
        src: `/sounds/music/background.mp3`,
        preload: true,
    }),
};
