import { TwoColumnGrid } from '../../../shared/components/TwoColumnGrid';
import { RoomSelectorOverview } from '../shared/components/RoomSelectorOverview';
import { LogoSection } from '../../../shared/components/LogoSection';
import { WoodenBackground } from '../../../shared/components/WoodenBackground';

export const RoomSelect = () => {
    return (
        <TwoColumnGrid leftColumnSizeMobile="30%" rightColumnSizeMobile="70%" leftColumnSize="50%" rightColumnSize="50%" height="100dvh">
            <LogoSection />

            <div className="relative h-full  p-4 py-6 lg:h-screen">
                <WoodenBackground />

                <div className="flex h-full flex-col items-center justify-center gap-10 lg:gap-20" data-testid="room-select">
                    <div className="flex flex-col items-center gap-2 lg:gap-4">
                        <h2 className="text-pretty text-xl font-bold text-white drop-shadow-solidGray lg:text-4xl" data-testid="room-select-title">
                            Speel online met klasgenoten
                        </h2>
                        <p className="text-pretty text-base text-white drop-shadow-sm lg:text-2xl" data-testid="room-select-description">
                            Kies samen een plek om naar toe te gaan!
                        </p>
                    </div>

                    <div className="flex flex-col gap-12 lg:gap-48">
                        <RoomSelectorOverview />
                    </div>
                </div>
            </div>
        </TwoColumnGrid>
    );
};
