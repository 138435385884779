"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientSession = void 0;
const types_1 = require("../types");
class ClientSession {
    token;
    loggedIn;
    state;
    /**
     * Represents the GameLift Realtime peer Id for the client. Set on
     * connection.
     */
    connectedPeerId;
    // private groupMembership: Set<number>;
    constructor(token) {
        this.token = token;
        // this.groupMembership = new Set<number>();
        this.state = types_1.SessionState.Ready;
        this.loggedIn = false;
    }
}
exports.ClientSession = ClientSession;
