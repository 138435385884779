import React, { createContext, useContext, useState } from 'react';

type GameContextType = {
    getExercises: () => void;
    animateMoveScreenView: () => void;
    animateMoveScreen: boolean;
};

const GameContext = createContext<GameContextType>(null!);
export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }: { children: React.ReactNode }) => {
    const [animateMoveScreen, setAnimateMoveScreen] = useState(false);

    const getExercises = () => {
        console.log('getting exercises');
    };

    const animateMoveScreenView = () => {
        setAnimateMoveScreen(true);
        setTimeout(() => {
            setAnimateMoveScreen(false);
        }, 2000);
    };

    return <GameContext.Provider value={{ getExercises, animateMoveScreenView, animateMoveScreen }}>{children}</GameContext.Provider>;
};
