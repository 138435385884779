"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectionType = exports.ConnectionStatus = exports.SessionState = void 0;
__exportStar(require("./events"), exports);
var SessionState;
(function (SessionState) {
    SessionState[SessionState["Ready"] = 0] = "Ready";
    SessionState[SessionState["Open"] = 1] = "Open";
    SessionState[SessionState["Connected"] = 2] = "Connected";
    SessionState[SessionState["Disconnected"] = 3] = "Disconnected";
})(SessionState || (exports.SessionState = SessionState = {}));
var ConnectionStatus;
(function (ConnectionStatus) {
    ConnectionStatus[ConnectionStatus["Disconnected"] = 0] = "Disconnected";
    ConnectionStatus[ConnectionStatus["Connecting"] = 1] = "Connecting";
    ConnectionStatus[ConnectionStatus["Connected"] = 2] = "Connected";
})(ConnectionStatus || (exports.ConnectionStatus = ConnectionStatus = {}));
/**
 * Defines the types of connection suites supported by the SDK.
 */
var ConnectionType;
(function (ConnectionType) {
    ConnectionType[ConnectionType["RtOverWsUnsecured"] = 0] = "RtOverWsUnsecured";
    ConnectionType[ConnectionType["RtOverWssDtlsTls12"] = 1] = "RtOverWssDtlsTls12";
})(ConnectionType || (exports.ConnectionType = ConnectionType = {}));
