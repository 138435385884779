"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketConnectionFactory = void 0;
const types_1 = require("../types");
const WebSocketConnection_1 = require("./WebSocketConnection");
/**
 * Creates instance of WebSocket connection used in the client.
 */
class WebSocketConnectionFactory {
    static create(options) {
        this.validateOptions(options);
        const connectionType = options.clientConfiguration.connectionType;
        switch (connectionType) {
            case types_1.ConnectionType.RtOverWsUnsecured:
                return new WebSocketConnection_1.WebSocketConnection(false);
            case types_1.ConnectionType.RtOverWssDtlsTls12:
                return new WebSocketConnection_1.WebSocketConnection(true);
        }
    }
    static validateOptions(options) {
        if (!options.clientConfiguration)
            throw new Error('Unable to create WebSocketConnection: clientConfiguration is undefined.');
    }
}
exports.WebSocketConnectionFactory = WebSocketConnectionFactory;
