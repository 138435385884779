"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRESENT_TYPE = void 0;
var PRESENT_TYPE;
(function (PRESENT_TYPE) {
    PRESENT_TYPE["BERRIES"] = "BERRIES";
    PRESENT_TYPE["BANANA"] = "BANANA";
    PRESENT_TYPE["CITRUS"] = "CITRUS";
    PRESENT_TYPE["GRAPES"] = "GRAPES";
})(PRESENT_TYPE || (exports.PRESENT_TYPE = PRESENT_TYPE = {}));
