import { Character as CharacterEnum } from '@repo/types';

import character1 from '../assets/img/Semsom-beestje-1.png';
import character2 from '../assets/img/Semsom-beestje-2.png';
import character3 from '../assets/img/Semsom-beestje-3.png';
import character4 from '../assets/img/Semsom-beestje-4.png';
import character5 from '../assets/img/Semsom-beestje-5.png';
import character6 from '../assets/img/Semsom-beestje-6.png';
import character7 from '../assets/img/Semsom-beestje-7.png';
import character8 from '../assets/img/Semsom-beestje-8.png';

export const characterImages: Record<CharacterEnum, string> = {
    [CharacterEnum.EXAMPLE_1]: character1,
    [CharacterEnum.EXAMPLE_2]: character2,
    [CharacterEnum.EXAMPLE_3]: character3,
    [CharacterEnum.EXAMPLE_4]: character4,
    [CharacterEnum.EXAMPLE_5]: character5,
    [CharacterEnum.EXAMPLE_6]: character6,
    [CharacterEnum.EXAMPLE_7]: character7,
    [CharacterEnum.EXAMPLE_8]: character8,
};
