import { Navigate, Route, Routes } from 'react-router-dom';
import { RoomSelect } from '../../modules/lobby/pages/RoomSelect.page';
import { CharacterSelect } from '../../modules/lobby/pages/CharacterSelect.page';
import { RoomProvider } from '../services/RoomContext';
import { Game } from '../../modules/game/pages/Game.page';
import { Result } from '../../modules/game/pages/Result.page';

export const ProtectedRouteController = () => {
    return (
        <RoomProvider>
            <Routes>
                <Route path="/room-select" element={<RoomSelect />} />
                <Route path="/character-select" element={<CharacterSelect />} />
                <Route path="/game" element={<Game />} />
                <Route path="/result" element={<Result />} />
                <Route path="*" element={<Navigate to="/room-select" replace />} />
            </Routes>
        </RoomProvider>
    );
};
