import { useCallback, useEffect, useState } from 'react';
import { cn } from '../../../../shared/utils';

interface KeyboardProps {
    inputClicked: (input: string) => void;
    disabled: boolean;
    keys?: string[];
}

const defaultKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '<'];

export const Keyboard = (props: KeyboardProps) => {
    const keys = props.keys ?? defaultKeys;
    const { inputClicked } = props;

    const [pressedKeys, setPressedKeys] = useState<string[]>([]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (props.disabled) return;

            const { key } = event;

            if (!pressedKeys.includes(key)) {
                setPressedKeys([...pressedKeys, key]);
            }

            if (key === 'Backspace' || key === 'Delete') {
                return inputClicked('<');
            }

            if (keys.includes(key)) {
                return inputClicked(key);
            }
        },
        [inputClicked, setPressedKeys, pressedKeys, props.disabled, keys],
    );

    const handleKeyUp = useCallback(
        (event: KeyboardEvent) => {
            setPressedKeys(pressedKeys.filter((key) => key !== event.key));
        },
        [setPressedKeys, pressedKeys],
    );

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [pressedKeys, handleKeyDown, handleKeyUp]);

    return (
        <div className="z-10 flex h-20 w-full items-center justify-between gap-1 border-t-4 border-black bg-gray-500 px-4 lg:h-40 lg:justify-center lg:gap-4" data-testid="keyboard">
            {keys.map((key) => (
                <button
                    key={key}
                    onClick={() => inputClicked(key)}
                    className="aspect-square w-full cursor-pointer transition-all last:ml-1 disabled:cursor-default disabled:opacity-50 lg:w-fit lg:last:ml-4"
                    disabled={props.disabled}
                >
                    <div
                        className={cn(
                            'shadow-solidGray grid size-full place-items-center rounded border-2 border-black bg-white text-center text-lg font-bold leading-4 transition-all lg:size-20 lg:rounded-lg lg:text-5xl',
                            pressedKeys.includes(key) && 'bg-black text-white',
                        )}
                    >
                        {key}
                    </div>
                </button>
            ))}
        </div>
    );
};
