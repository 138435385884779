import { useEffect, useState } from 'react';
import { TwoColumnGrid } from '../../../shared/components/TwoColumnGrid';
import { TimerButton } from '../../../shared/components/TimerButton';
import { Character } from '../../game/shared/components/Character';
import { useRoom } from '../../../shared/services/RoomContext';
import { Character as CharacterEnum, OP_CODE } from '@repo/types';
import { LoadBar } from '../../../shared/components/LoadBar';
import { Config } from '@repo/common';
import GameLiftService from '../../../shared/services/GameLiftService';
import { cn } from '../../../shared/utils';
import { Navigate } from 'react-router-dom';
import { characterImages, SoundKey } from '../../../shared/data';
import AudioService from '../../../shared/services/AudioService';

import { WoodenBackground } from '../../../shared/components/WoodenBackground';
import raceTracks from '../../../shared/assets/img/race-tracks.png';
import signBoard from '../../../shared/assets/img/sign-board.png';
import { LeaveRoomButton } from '../../../shared/components/LeaveRoomButton';

export const CharacterSelect = () => {
    const characters = Object.values(CharacterEnum);
    const { room, currentPlayer, leaveRoom, otherPlayers } = useRoom();

    const [awaitingStart, setAwaitingStart] = useState(false);
    const [selectedCharacters, setSelectedCharacters] = useState<CharacterEnum[]>([]);

    useEffect(() => {
        setSelectedCharacters(room?.players.flatMap((p) => p.character) ?? []);
    }, [room]);

    if (!room) {
        console.error('Room not found');
        return <Navigate to="/room-select" />;
    }

    const handleSelectCharacter = async (character: CharacterEnum) => {
        AudioService.play(SoundKey.CLICK);

        const client = await GameLiftService.client;
        client.send(
            client.newMessage(OP_CODE.UPDATE_CHARACTER, {
                character,
            }),
        );
    };

    return (
        <TwoColumnGrid leftColumnSize="50%" rightColumnSize="50%" height="100dvh">
            <div className="relative overflow-hidden" data-testid="character-select-background">
                <div
                    style={{
                        backgroundImage: `url(${raceTracks})`,
                        backgroundSize: '115%',
                        backgroundPosition: 'top',
                        backgroundRepeat: 'no-repeat',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        width: '100%',
                        zIndex: '-1',
                    }}
                ></div>

                <div className="mx-auto mt-[20%] grid max-w-[82%] grid-cols-4 items-end justify-items-center" data-testid="other-players">
                    {otherPlayers?.map((player, index) =>
                        player ? (
                            <div
                                key={player.id}
                                className="aspect-square size-32 select-none p-4 drop-shadow-solidGray transition-all"
                                data-testid={`other-player-${player.id}`}
                            >
                                <Character character={player.character} />
                            </div>
                        ) : (
                            <div
                                key={index}
                                className="grid aspect-square size-32 animate-pulse place-items-center text-2xl drop-shadow-solidGray lg:text-6xl"
                                data-testid={`other-player-placeholder-${index}`}
                            >
                                ⏳
                            </div>
                        ),
                    )}

                    {currentPlayer?.character && (
                        <div
                            className="aspect-square size-32 select-none p-4 drop-shadow-solidGray transition-all"
                            data-testid="current-player"
                        >
                            <Character character={currentPlayer.character} />
                        </div>
                    )}
                </div>

                <div className="absolute left-2 top-[30vh] w-fit max-w-[5%]" data-testid="sign-board">
                    <div className="relative w-10 text-6xl lg:w-16">
                        <img src={signBoard} alt="sign board" className="w-full" />
                        <div className="absolute left-2 top-2 text-3xl lg:text-5xl">{room.icon}</div>
                    </div>
                </div>
            </div>

            <div className="relative flex h-full items-center justify-center lg:h-screen" data-testid="character-selection">
                <LeaveRoomButton leaveRoom={leaveRoom} />

                <div className="w-full">
                    <WoodenBackground />

                    {awaitingStart ? (
                        <>
                            <div className="flex flex-col items-center justify-center gap-4" data-testid="awaiting-start">
                                <h2 className="text-4xl font-semibold text-white drop-shadow-solidGray">Wachten op spelers..</h2>
                                <div className="mx-auto mt-4 h-8 w-96 rounded-lg bg-gray-800/90 shadow-solidGray">
                                    <LoadBar
                                        className="bg-yellow-400"
                                        duration={new Date(room.starts_at).getTime() - Date.now()}
                                        timerEnded={() => {}}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <div
                            className="flex max-h-screen flex-col items-center justify-center gap-16 overflow-y-auto py-16"
                            data-testid="character-options"
                        >
                            <h1 className="text-pretty text-2xl font-bold text-white drop-shadow-solidGray lg:text-4xl">Kies een dier</h1>

                            <div className="flex w-fit max-w-2xl flex-wrap items-center justify-center gap-4 px-4 xl:gap-6 xl:px-4">
                                {characters.map((character) => (
                                    <button
                                        onClick={() => {
                                            if (!selectedCharacters.includes(character)) handleSelectCharacter(character).then();
                                        }}
                                        key={character}
                                        className={cn(
                                            'shadow-solidGray flex aspect-square size-full max-w-32 cursor-pointer items-center justify-center rounded-full border-2 border-black bg-gray-300 outline-none transition-all hover:scale-105 lg:max-w-36 2xl:max-w-48',
                                            currentPlayer?.character === character
                                                ? 'cursor-pointer bg-yellow-300 ring-8 ring-white/75 hover:scale-105 lg:ring-[.65rem]'
                                                : selectedCharacters.includes(character)
                                                  ? 'cursor-not-allowed bg-gray-800'
                                                  : 'cursor-pointer bg-gray-300 hover:scale-105',
                                        )}
                                        data-testid={`character-button`}
                                    >
                                        <img
                                            src={characterImages[character]}
                                            className={cn(
                                                'size-full object-contain p-8',
                                                selectedCharacters.includes(character) &&
                                                    currentPlayer?.character !== character &&
                                                    'opacity-50',
                                            )}
                                            alt={character}
                                            data-testid={`character-image-${character}`}
                                        />
                                    </button>
                                ))}
                            </div>

                            <TimerButton
                                duration={Config.ROOM_WAIT_TIME}
                                text="Kies"
                                timerEnded={() => setAwaitingStart(true)}
                                onClick={() => {
                                    AudioService.play(SoundKey.CLICK);
                                    setAwaitingStart(true);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </TwoColumnGrid>
    );
};
