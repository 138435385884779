"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
const types_1 = require("@repo/types");
const AWS_REGION = 'eu-central-1';
const FLEET_ID = 'fleet-90608c93-a248-4de8-ad6f-726f9da4cd9a';
// This is the max players that can connect at once.
// Used by the login lambda and GameLift server.
const MAX_CONNECTED_PLAYERS = 1024;
// The time between ticks, not really used for any logic
const TICK_TIME = 1000;
const LAMBDAS = {
    LOGIN: 'https://tdti3iqz34kenuxvfetkvsgkp40nrftw.lambda-url.eu-central-1.on.aws/',
};
const PLAYER_DISCONNECT_TIMEOUT = 10000;
const ROOM_ICONS = [
    '🍎',
    '🍌',
    '🍉',
    '🍒',
    '🥕',
    '🥝',
    '🍍',
    '🍊',
    '🍇',
    '🌽',
    '🥐',
    '🥦',
    '🍅',
    '🥒',
    '🍓',
    '🥜',
    '🥔',
    '🍞',
    '🥥',
    '🍋',
];
const ROOM_MAX_PLAYERS = 4;
const ROOM_DISPLAY_COUNT = 10;
// The time the players have to pick their characters.
const ROOM_WAIT_TIME = 10000;
// How long the present popup should be shown
const PRESENT_DISPLAY_TIME = 2000;
const PRESENT_EFFECT_OVERLAY_DURATIONS = {
    [types_1.PRESENT_TYPE.BANANA]: 3500,
    [types_1.PRESENT_TYPE.BERRIES]: PRESENT_DISPLAY_TIME + 500,
    [types_1.PRESENT_TYPE.CITRUS]: PRESENT_DISPLAY_TIME,
    [types_1.PRESENT_TYPE.GRAPES]: 3500,
};
const PRESENT_LOCATIONS = [20, 40, 60, 80];
// How long to show the congrats or game over screen
const ROOM_GAME_OVER_WAIT_TIME = 5000;
const ROOM_RESULTS_DISPLAY_TIME = 10000;
exports.Config = {
    FLEET_ID,
    AWS_REGION,
    MAX_CONNECTED_PLAYERS,
    TICK_TIME,
    LAMBDAS,
    PLAYER_DISCONNECT_TIMEOUT,
    ROOM_ICONS,
    ROOM_MAX_PLAYERS,
    ROOM_DISPLAY_COUNT,
    ROOM_WAIT_TIME,
    PRESENT_DISPLAY_TIME,
    PRESENT_EFFECT_OVERLAY_DURATIONS,
    ROOM_GAME_OVER_WAIT_TIME,
    ROOM_RESULTS_DISPLAY_TIME,
    PRESENT_LOCATIONS,
};
