import { PRESENT_TYPE } from '@repo/types';

import banana from '../assets/img/banaan.png';
import berries from '../assets/img/rode besjes.png';
import citrus from '../assets/img/citroen.png';
import grapes from '../assets/img/druiven.png';

import characterBanana from '../assets/img/character-banana.webp';
import characterBerries from '../assets/img/character-berries.webp';
import characterCitrus from '../assets/img/character-citrus.webp';
import characterGrapes from '../assets/img/character-grapes.webp';

export const presentImages: Record<PRESENT_TYPE, string> = {
    [PRESENT_TYPE.BANANA]: banana,
    [PRESENT_TYPE.BERRIES]: berries,
    [PRESENT_TYPE.CITRUS]: citrus,
    [PRESENT_TYPE.GRAPES]: grapes,
};

export const characterPresentImages: Record<PRESENT_TYPE, string> = {
    [PRESENT_TYPE.BANANA]: characterBanana,
    [PRESENT_TYPE.BERRIES]: characterBerries,
    [PRESENT_TYPE.CITRUS]: characterCitrus,
    [PRESENT_TYPE.GRAPES]: characterGrapes,
};
