"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientConfiguration = void 0;
const types_1 = require("../types");
const WebSocketConnectionFactory_1 = require("./WebSocketConnectionFactory");
/**
 * Defines connection properties to use when talking to GameLift Realtime
 * Servers.
 */
class ClientConfiguration {
    /*
     *  Type of connection suite to use to connect to GameLift Realtime Servers.
     */
    connectionType = types_1.ConnectionType.RtOverWsUnsecured;
    /*
     * Provides implementation of reliable connections based on the ConnectionType.
     */
    webSocketConnectionFactory = new WebSocketConnectionFactory_1.WebSocketConnectionFactory();
    /*
     * Create a default ClientConfiguration, which contains the following configuration for the game client:
     * 1. Connects to GameLift Realtime Server
     * 2. Establishes reliable connection to server with a WebSocket
     * 3. Connection is Unsecured
     *
     * @returns The default game client configuration.
     */
    static createDefault() {
        return new ClientConfiguration();
    }
}
exports.ClientConfiguration = ClientConfiguration;
