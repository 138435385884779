interface LeaveRoomButtonProps {
    leaveRoom: () => void;
}

export const LeaveRoomButton = ({ leaveRoom }: LeaveRoomButtonProps) => {
    return (
        <div className="group absolute left-0 top-0 z-20 h-20">
            <button
                onClick={leaveRoom}
                className="flex h-full w-fit items-center gap-2 fill-white p-5 opacity-85 outline-none transition-all hover:opacity-100 hover:drop-shadow-md"
                data-testid="leave-room-button"
            >
                <svg className="aspect-square h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32zm-217.9-9.9L11.3 283.3C4.1 276.1 0 266.2 0 256s4.1-20.1 11.3-27.3L134.1 105.9c6.4-6.4 15-9.9 24-9.9c18.7 0 33.9 15.2 33.9 33.9l0 62.1 128 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-128 0 0 62.1c0 18.7-15.2 33.9-33.9 33.9c-9 0-17.6-3.6-24-9.9z" />
                </svg>

                <p className="w-full -translate-x-2 font-semibold text-white opacity-0 drop-shadow transition-all group-hover:translate-x-0 group-hover:opacity-100">
                    Spel verlaten
                </p>
            </button>
        </div>
    );
};
