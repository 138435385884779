import React from 'react';

const spinnerStyle: React.CSSProperties = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    display: 'inline-block',
    borderTop: '8px solid #FFF',
    borderRight: '8px solid transparent',
    boxSizing: 'border-box',
    animation: 'rotation 1s linear infinite',
};

const keyframesStyle = `
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const circleStyle: React.CSSProperties = {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#FFF',
    display: 'inline-block',
    position: 'absolute',
    top: '8px',
    right: '240px',
    left: '80px',
};

export const Spinner: React.FC = () => {
    return (
        <>
            <style>{keyframesStyle}</style>
            <span style={spinnerStyle}>
                <span style={circleStyle}></span>
            </span>
        </>
    );
};
