import { useEffect, useRef, useState } from 'react';
import { cn } from '../utils';

interface TimerButtonProps {
    duration: number;
    text: string;
    timerEnded?: () => void;
    onClick?: () => void;
    className?: string;
}

export const TimerButton = (props: TimerButtonProps) => {
    const { duration, text, timerEnded, onClick } = props;
    const [progress, setProgress] = useState(100);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const runTimer = () => {
        const startTime = Date.now();
        const interval = 100;

        timerRef.current = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const remainingTime = duration - elapsedTime;
            const newProgress = Math.max((remainingTime / duration) * 100, 0);

            setProgress(newProgress);

            if (elapsedTime >= duration) {
                endTimer();
                if (timerEnded) timerEnded();
            }
        }, interval);
    };

    const endTimer = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    };

    const handleOnClick = () => {
        endTimer();
        if (onClick) {
            onClick();
        }
    };

    useEffect(() => {
        runTimer();
        return () => endTimer();
    }, [duration]);

    return (
        <button
            className={cn('shadow-solidGray w-64 rounded-lg border-2 border-black bg-yellow-300 p-2 text-black', props.className)}
            onClick={handleOnClick} data-testid="timer-button"
        >
            <div className="h-4 w-full rounded-md bg-gray-600/50">
                <div className="h-4 rounded-md bg-gray-900 transition-all duration-200" style={{ width: `${progress}%` }}></div>
            </div>
            <p className="pt-1 text-xl font-semibold">{text}</p>
        </button>
    );
};
