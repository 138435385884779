import React from 'react';
import { cn } from '../utils';

import woodenBackground from '../assets/img/wooden-background.png';

interface BackgroundProps {
    dynamicBorder?: boolean;
}

export const WoodenBackground: React.FC<BackgroundProps> = ({ dynamicBorder = true }: BackgroundProps) => {
    return (
        <div
            className={cn(
                'bg-gradient-radial absolute inset-0 -z-10 size-full from-amber-950 to-black ',
                dynamicBorder && 'border-t-4 border-black lg:border-l-4 lg:border-t-0',
            )}
        >
            <img src={woodenBackground} alt="wooden background" className="size-full object-cover opacity-60" />
        </div>
    );
};
