import './App.css';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRouteController } from './shared/components/ProtectedRouteController';
import { ProtectedRoute } from './ProtectedRoute';
import { Start } from './modules/lobby/pages/start.page';
import { AuthProvider, useAuth } from './shared/services/AuthContext';
import { useEffect } from 'react';
import { GameProvider } from './shared/services/GameContext';
import GameLiftService from './shared/services/GameLiftService';
import AudioService from './shared/services/AudioService';
import { SoundKey } from './shared/data';

function App() {
    const { checkAuth } = useAuth();

    useEffect(() => {
        const check = async () => {
            const isAuthenticated = await checkAuth();
            console.log('isAuthenticated', isAuthenticated);
        };

        check().then();
    }, [checkAuth]);

    useEffect(() => {
        const onBeforeUnload = () => {
            if (!window.location.pathname.startsWith('/room-select'))
                return 'Weet je zeker dat je game wilt verlaten? Je gaat dan uit deze ronde.';
        };

        window.addEventListener('beforeunload', onBeforeUnload);
        return () => window.removeEventListener('beforeunload', onBeforeUnload);
    }, []);

    useEffect(() => {
        AudioService.play(SoundKey.MUSIC_BACKGROUND);
        return () => AudioService.stop(SoundKey.MUSIC_BACKGROUND);
    });

    GameLiftService.init().then();

    return (
        <div className="App">
            <AuthProvider>
                <GameProvider>
                    <Routes>
                        <Route path="/" element={<Start />} />
                        <Route path="*" element={<ProtectedRoute component={ProtectedRouteController} />} />
                    </Routes>
                </GameProvider>
            </AuthProvider>
        </div>
    );
}

export default App;
