import { Question } from '../../modules/game/shared/types/Question';

const generateQuestions = (operation: (i: number, j: number) => number, formatQuestion: (i: number, j: number) => string): Question[] => {
    const questions: Question[] = [];

    for (let i = 1; i <= 9; i++) {
        for (let j = 1; j <= 9; j++) {
            questions.push({
                questionText: formatQuestion(i, j),
                answer: operation(i, j),
            });
        }
    }

    return questions;
};

const generateMultiplicationTables = () => {
    return generateQuestions(
        (i, j) => i * j,
        (i, j) => `${i} x ${j} =`,
    );
};

const generateAdditions = () => {
    return generateQuestions(
        (i, j) => i + j,
        (i, j) => `${i} + ${j} =`,
    );
};

const generateSubtractions = () => {
    return generateQuestions(
        (i, j) => i - j,
        (i, j) => `${i} - ${j} =`,
    );
};

const Questions = [
    // Filter out questions with times 1
    ...generateMultiplicationTables().filter((q) => q.answer > 9),
    ...generateAdditions(),
    ...generateSubtractions(),
].filter((question) => question.answer > 0);
export default Questions;
