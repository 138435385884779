import { Character } from './Character';
import { useRoom } from '../../../../shared/services/RoomContext';
import { Navigate } from 'react-router-dom';
import { GamePlayer, PRESENT_TYPE } from '@repo/types';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { characterPresentImages } from '../../../../shared/data';
import { cn } from '../../../../shared/utils';
import { Config } from '@repo/common';

import raceTracks from '../../../../shared/assets/img/race-tracks.png';
import present from '../../../../shared/assets/img/present.webp';

interface GameViewProps {
    playerPresentEffects: Record<string, PRESENT_TYPE>;
    distanceIndicator: {
        max: number;
        current: number;
        visible: boolean;
    };
}

export const GameView: React.FC<GameViewProps> = ({ playerPresentEffects, distanceIndicator }: GameViewProps) => {
    const { room, currentPlayer, otherPlayers } = useRoom();

    const [transformedDistanceIndicatorMax, setTransformedDistanceIndicatorMax] = useState<number | null>(null);

    const currentPlayerRef = useRef(null);
    const trackRef = useRef<HTMLDivElement>(null);

    const calculateIndicatorSize = (max: number, trackRef: RefObject<HTMLDivElement>) => {
        if (!trackRef.current) return;
        const bounds = trackRef.current.getBoundingClientRect();
        setTransformedDistanceIndicatorMax((bounds.height / 100) * max);
    };

    useEffect(() => {
        if (!currentPlayer || currentPlayer.position === undefined) return;

        const element = currentPlayerRef.current as HTMLElement | null;

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [currentPlayer]);

    useEffect(() => calculateIndicatorSize(distanceIndicator.max, trackRef), [distanceIndicator.max]);

    useEffect(() => {
        const handleResize = () => calculateIndicatorSize(distanceIndicator.max, trackRef);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [distanceIndicator.max]);

    if (!room) {
        console.error('Room not found');
        return <Navigate to="/room-select" />;
    }

    const getPlayerTrack = (player: GamePlayer, ref?: React.MutableRefObject<null>, isCurrentPlayer?: boolean) => {
        return (
            <div className="relative flex flex-col" key={player.id} data-testid={`game-view-player-${player.id}`}>
                <div className="absolute left-1/2 top-0 -z-20 h-full w-4 -translate-x-1/2 rounded-full bg-neutral-800/10"></div>

                {Config.PRESENT_LOCATIONS.filter((location) => location > player.position).map((location, idx) => (
                    <div
                        key={'present-' + player.id + '-' + location}
                        className="absolute left-0 top-0 z-20 ml-16 flex w-full max-w-20 items-end lg:ml-12 lg:max-w-none"
                        style={{
                            height: `${location}%`,
                        }}
                        data-testid={`present-${idx + 1}`}
                    >
                        <div className="w-full animate-scale-in text-center text-6xl">
                            <img alt={'present ' + (idx + 1)} src={present} className="size-full object-contain" />
                        </div>
                    </div>
                ))}

                <div
                    className="transition-all duration-500 ease-in-out"
                    style={{
                        height: `calc(${player.position}% - 8rem)`, // We subtract 8rem as that is the height of the character
                    }}
                    data-testid={`player-position`}
                />

                <div className="relative" ref={ref}>
                    <div
                        className={cn(
                            'drop-shadow-solidGray size-32 select-none rounded-full p-4 transition-all',
                            playerPresentEffects[player.id] === PRESENT_TYPE.BANANA && 'rotate-90',
                        )}
                        data-testid={`character-${player.id}`}
                    >
                        <Character character={player.character} />
                    </div>

                    {playerPresentEffects[player.id] && (
                        <img
                            className="absolute inset-0 aspect-square w-full animate-fade-in object-contain"
                            src={characterPresentImages[playerPresentEffects[player.id]]}
                            alt=""
                            data-testid={`present-effect-${player.id}`}
                        />
                    )}

                    {isCurrentPlayer && currentPlayer && currentPlayer?.position < 100 ? (
                        <div
                            className={cn(
                                'animate-fade-in absolute left-1/2 top-full -z-10 -mt-[30%] -translate-x-1/2 opacity-0 transition-all duration-75',
                                distanceIndicator.visible && 'opacity-100',
                            )}
                            data-testid="distance-indicator"
                        >
                            <div
                                className="relative w-10"
                                style={{
                                    height: transformedDistanceIndicatorMax + 'px',
                                }}
                            >
                                <div className="absolute inset-0 -z-10 size-full rounded-lg border-4 border-black/75 bg-black/50"></div>
                                <div
                                    className="relative w-full rounded-lg border-4 border-black bg-yellow-300 transition-all"
                                    style={{
                                        height: distanceIndicator.current + '%',
                                    }}
                                >
                                    <div className="absolute -bottom-3 left-1/2 size-5 -translate-x-1/2 rounded-full bg-black"></div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="relative overflow-y-auto" data-testid="game-view">
                <div
                    className="-z-0 bg-no-repeat"
                    style={{
                        aspectRatio: '1472/3296',
                        backgroundImage: `url(${raceTracks})`,
                        backgroundSize: '100%',
                    }}
                    ref={trackRef}
                    data-testid="race-track"
                >
                    <div className="relative z-20 mx-auto size-full max-w-[70%] py-20 lg:py-40">
                        <div className="grid size-full grid-cols-4 justify-items-center">
                            {otherPlayers?.map((player) => (player ? getPlayerTrack(player) : <div></div>))}
                            {getPlayerTrack(currentPlayer!, currentPlayerRef, true)}
                        </div>

                        <div className="absolute inset-0 size-full py-20 lg:py-40">
                            <div className="relative size-full">
                                {Config.PRESENT_LOCATIONS.map((location) => (
                                    <div
                                        style={{
                                            top: `${location - 0.8}%`,
                                            background: `repeating-linear-gradient(90deg, rgba(0,0,0,.5) 0, rgba(0,0,0,.5) 1rem, transparent 1rem, transparent 2rem)`,
                                        }}
                                        className="absolute inset-0 -z-20 h-1 w-full drop-shadow"
                                        key={`present-location-${location}`}
                                        data-testid={`present-location-${location}`}
                                    ></div>
                                ))}

                                <div
                                    style={{
                                        background: `repeating-linear-gradient(90deg, transparent 0, transparent 2rem, white 2rem, white 4rem)`,
                                    }}
                                    className="absolute bottom-[2.5%] left-0 -z-20 h-2 w-full drop-shadow-solidGray"
                                    data-testid="finish-line"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

