"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toVarInt = void 0;
function toVarInt(value) {
    if (value < 0 || value > Math.pow(2, 31)) {
        throw new Error(`Unable to encode value '${value}' to var int`);
    }
    const varIntBytes = [];
    let offset = 0;
    while (value >= 0x80) {
        varIntBytes[offset++] = value | 0x80;
        value >>>= 7;
    }
    varIntBytes[offset] = value;
    return Uint8Array.from(varIntBytes);
}
exports.toVarInt = toVarInt;
