"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameLiftRealtimeClient = void 0;
const types_1 = require("./types");
const ClientConfiguration_1 = require("./classes/ClientConfiguration");
const WebSocketConnectionFactory_1 = require("./classes/WebSocketConnectionFactory");
const RTMessage_1 = require("./classes/RTMessage");
const strongly_typed_events_1 = require("strongly-typed-events");
const buffer_1 = require("buffer");
const ClientSession_1 = require("./classes/ClientSession");
const messages_1 = require("./messages");
const types_2 = require("@repo/types");
class GameLiftRealtimeClient {
    reconnectData;
    logging;
    static maxWebSocketMessageBytes = 4096;
    session;
    webSocketConnection;
    connectionStatus = types_1.ConnectionStatus.Disconnected;
    serverEndpoint;
    onOpen = new strongly_typed_events_1.SimpleEventDispatcher();
    onClose = new strongly_typed_events_1.SimpleEventDispatcher();
    onError = new strongly_typed_events_1.SimpleEventDispatcher();
    onLogin = new strongly_typed_events_1.SimpleEventDispatcher();
    onDataReceived = new strongly_typed_events_1.SimpleEventDispatcher();
    constructor(connectionToken, reconnectData, logging = true) {
        this.reconnectData = reconnectData;
        this.logging = logging;
        this.session = new ClientSession_1.ClientSession(connectionToken);
        this.serverEndpoint = connectionToken.serverEndpoint;
    }
    /*
     * False until client connects to GameLift Realtime. True while connected to any server.
     */
    get connected() {
        return this.connectionStatus === types_1.ConnectionStatus.Connected;
    }
    /**
     * Connect to a Realtime server
     * @returns Connection status of the client.
     */
    connect() {
        if (!this.serverEndpoint) {
            throw new Error('Cannot connect to GameLift Realtime without serverEndpoint');
        }
        const connectionFactoryOptions = {
            hostName: this.serverEndpoint,
            clientConfiguration: ClientConfiguration_1.ClientConfiguration.createDefault(),
        };
        this.webSocketConnection = WebSocketConnectionFactory_1.WebSocketConnectionFactory.create(connectionFactoryOptions);
        this.webSocketConnection.connectionOpen.subscribe((e) => this.onConnectionOpen(e));
        this.webSocketConnection.connectionClose.subscribe((e) => this.onConnectionClose(e));
        this.webSocketConnection.connectionError.subscribe((e) => this.onConnectionError(e));
        this.webSocketConnection.messageReceived.subscribe((e) => this.onMessageReceived(e));
        this.webSocketConnection.initializeWebSocket(this.serverEndpoint);
    }
    /**
     * When connected to a game session, disconnects the game client from the
     * game session.
     */
    disconnect() {
        this.connectionStatus = types_1.ConnectionStatus.Disconnected;
        this.webSocketConnection?.close();
    }
    /**
     * Create a new Realtime Message using the passed OpCode and the client's connected identity.
     */
    newMessage(opCode, payload) {
        let payloadBinary;
        if (payload) {
            const payloadBuffer = buffer_1.Buffer.from(JSON.stringify(payload), 'utf-8');
            payloadBinary = Uint8Array.from(payloadBuffer);
        }
        return new RTMessage_1.RTMessage(opCode, this.session.connectedPeerId, payloadBinary);
    }
    send(message) {
        if (!this.connected) {
            throw new Error('Could not to send message: Not connected to server');
        }
        const payloadSize = message.payloadBinary ? message.payloadBinary.length : 0;
        if (payloadSize > GameLiftRealtimeClient.maxWebSocketMessageBytes) {
            throw new Error(`The payload you're attempting to send on message with opCode '${message.opCode}' is larger than the maximum allowed bytes (${GameLiftRealtimeClient.maxWebSocketMessageBytes}).`);
        }
        const packet = message.toPacket();
        packet.setSender(this.session.connectedPeerId);
        packet.setReliable(true);
        return this.webSocketConnection?.send(packet);
    }
    onConnectionOpen(event) {
        this.connectionStatus = types_1.ConnectionStatus.Connected;
        const loginCommand = new messages_1.LoginCommand(this.session.token.playerSessionId, this.session.token.connectedPeerId, this.session.token.payload);
        if (this.reconnectData && this.reconnectData.player_id && this.reconnectData.reconnect_token) {
            const payload = this.reconnectData;
            loginCommand.setPayload(JSON.stringify(payload));
        }
        this.send(loginCommand);
        this.onOpen.dispatch(event);
    }
    onConnectionClose(event) {
        this.session.loggedIn = false;
        this.connectionStatus = types_1.ConnectionStatus.Disconnected;
        this.onClose.dispatch(event);
    }
    onConnectionError(event) {
        this.onError.dispatch(event);
    }
    onMessageReceived(message) {
        switch (message.opCode) {
            case types_2.OP_CODE.LOGIN_RESULT: {
                this.handleLoginResponse(message);
                break;
            }
        }
        this.onDataReceived.dispatch(message);
        if (this.logging) {
            const msg = { ...message, payload: message.payloadBinary ? message.getPayloadObject() : undefined };
            delete msg.payloadBinary;
            console.log('[RTC] Received message: ', msg);
        }
    }
    handleLoginResponse(result) {
        this.session.connectedPeerId = result.targetPlayer;
        this.session.loggedIn = result.success;
        this.onLogin.dispatch(this.session);
    }
}
exports.GameLiftRealtimeClient = GameLiftRealtimeClient;
