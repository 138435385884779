"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RTMessage = void 0;
const buffer_1 = require("buffer");
const Packet_pb_1 = require("../proto/Packet_pb");
class RTMessage {
    opCode;
    sender;
    targetPlayer = 0;
    targetGroup = -1;
    payloadBinary;
    constructor(opCode, sender, payload) {
        this.opCode = opCode;
        this.sender = sender;
        if (payload) {
            this.payloadBinary = payload instanceof Uint8Array ? payload : Uint8Array.from(buffer_1.Buffer.from(payload));
        }
        return this;
    }
    static fromPacket(packet) {
        const message = new RTMessage(packet.getOpcode(), packet.getSender());
        const payload = packet.getPayload();
        if (payload)
            message.payloadBinary = Uint8Array.from(buffer_1.Buffer.from(payload));
        const targetPlayer = packet.getTargetplayer();
        if (targetPlayer)
            message.targetPlayer = targetPlayer;
        const targetGroup = packet.getTargetgroup();
        if (targetGroup)
            message.targetGroup = targetGroup;
        return message;
    }
    getPayloadObject() {
        if (!this.payloadBinary)
            throw new Error('Payload not set');
        const payloadBuffer = buffer_1.Buffer.from(this.payloadBinary);
        return JSON.parse(payloadBuffer.toString());
    }
    toPacket() {
        const packet = new Packet_pb_1.Packet();
        packet.setOpcode(this.opCode);
        packet.setReliable(true);
        packet.setSender(this.sender ?? 0);
        packet.setTargetplayer(this.targetPlayer);
        packet.setTargetgroup(this.targetGroup);
        if (this.payloadBinary)
            packet.setPayload(this.payloadBinary);
        return this.toInnerPacket(packet);
    }
    // This is required because LoginCommand overwrites this method.
    toInnerPacket(packet) {
        return packet;
    }
    setPayload(payload) {
        this.payloadBinary = payload instanceof Uint8Array ? payload : Uint8Array.from(buffer_1.Buffer.from(payload));
    }
}
exports.RTMessage = RTMessage;
