"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSocketConnection = exports.ClientConfiguration = exports.ClientSession = exports.GameLiftRealtimeClient = exports.WebSocketConnectionFactory = exports.RTMessage = void 0;
__exportStar(require("./types"), exports);
__exportStar(require("./messages"), exports);
var RTMessage_1 = require("./classes/RTMessage");
Object.defineProperty(exports, "RTMessage", { enumerable: true, get: function () { return RTMessage_1.RTMessage; } });
var WebSocketConnectionFactory_1 = require("./classes/WebSocketConnectionFactory");
Object.defineProperty(exports, "WebSocketConnectionFactory", { enumerable: true, get: function () { return WebSocketConnectionFactory_1.WebSocketConnectionFactory; } });
var client_1 = require("./client");
Object.defineProperty(exports, "GameLiftRealtimeClient", { enumerable: true, get: function () { return client_1.GameLiftRealtimeClient; } });
var ClientSession_1 = require("./classes/ClientSession");
Object.defineProperty(exports, "ClientSession", { enumerable: true, get: function () { return ClientSession_1.ClientSession; } });
var ClientConfiguration_1 = require("./classes/ClientConfiguration");
Object.defineProperty(exports, "ClientConfiguration", { enumerable: true, get: function () { return ClientConfiguration_1.ClientConfiguration; } });
var WebSocketConnection_1 = require("./classes/WebSocketConnection");
Object.defineProperty(exports, "WebSocketConnection", { enumerable: true, get: function () { return WebSocketConnection_1.WebSocketConnection; } });
