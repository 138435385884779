"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Character = void 0;
var Character;
(function (Character) {
    Character["EXAMPLE_1"] = "EXAMPLE_1";
    Character["EXAMPLE_2"] = "EXAMPLE_2";
    Character["EXAMPLE_3"] = "EXAMPLE_3";
    Character["EXAMPLE_4"] = "EXAMPLE_4";
    Character["EXAMPLE_5"] = "EXAMPLE_5";
    Character["EXAMPLE_6"] = "EXAMPLE_6";
    Character["EXAMPLE_7"] = "EXAMPLE_7";
    Character["EXAMPLE_8"] = "EXAMPLE_8";
})(Character || (exports.Character = Character = {}));
