import React from 'react';
import { cn } from '../../../../shared/utils';

interface RoomTileButtonProps {
    players: number;
    icon: string;
    onClick: () => void;
    className?: string;
}

export const RoomTileButton: React.FC<RoomTileButtonProps> = (props: RoomTileButtonProps) => {
    const filledCircle = (key: number) => (
        <div key={key} className="size-3 rounded-full border border-black bg-yellow-400 lg:border-2" data-testid={`filled-circle-${key}`}></div>
    );
    const emptyCircle = (key: number) => <div key={key} className="size-3 rounded-full border border-black bg-black lg:border-2" data-testid={`empty-circle-${key}`}></div>;

    const circles = [];
    for (let i = 0; i < 4; i++) {
        circles.push(i < props.players ? filledCircle(i) : emptyCircle(i));
    }

    return (
        <button
            className={cn(
                `animate-fade-in shadow-solidGray relative grid size-16 place-items-center rounded-xl border-4 p-0 outline-none focus-visible:ring focus-visible:ring-blue-500/75 lg:size-20`,
                props.players === 4 ? 'cursor-not-allowed border-transparent bg-gray-600' : 'cursor-pointer border-black bg-white',
                props.className,
            )}
            onClick={props.players === 4 ? undefined : props.onClick}
            data-testid="room-tile-button"
        >
            <div className={cn('text-3xl lg:text-5xl', props.players === 4 && 'opacity-50')} data-testid="room-tile-icon">{props.icon}</div>

            {props.players === 4 ? (
                <div className="absolute -bottom-1.5 font-fredoka text-sm font-bold text-black" data-testid="room-full-label">Vol</div>
            ) : (
                <div className="absolute -bottom-2 flex items-center justify-center gap-0.5 text-lg" data-testid="room-player-circles">{circles}</div>
            )}
        </button>
    );
};

