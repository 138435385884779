/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

import * as proto_CoreCommands_pb from './CoreCommands_pb.js';
import * as proto_GroupMessages_pb from './GroupMessages_pb.js';
goog.exportSymbol('proto.com.gamelift.rt.proto.Packet', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.Packet = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.gamelift.rt.proto.Packet.oneofGroups_);
};
goog.inherits(proto.com.gamelift.rt.proto.Packet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.Packet.displayName = 'proto.com.gamelift.rt.proto.Packet';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.gamelift.rt.proto.Packet.oneofGroups_ = [[30, 31, 32, 33, 37, 38, 34, 35, 36, 40, 41, 42, 43]];

/**
 * @enum {number}
 */
proto.com.gamelift.rt.proto.Packet.MessageCase = {
  MESSAGE_NOT_SET: 0,
  LOGIN: 30,
  LOGINRESULT: 31,
  PING: 32,
  PINGRESULT: 33,
  VERIFYIDENTITY: 37,
  VERIFYIDENTITYRESULT: 38,
  UDPCONNECT: 34,
  PLAYERCONNECT: 35,
  PLAYERDISCONNECT: 36,
  JOINGROUP: 40,
  LEAVEGROUP: 41,
  REQUESTGROUPMEMBERSHIP: 42,
  GROUPMEMBERSHIPUPDATE: 43,
};

/**
 * @return {proto.com.gamelift.rt.proto.Packet.MessageCase}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getMessageCase = function () {
  return /** @type {proto.com.gamelift.rt.proto.Packet.MessageCase} */ (
    jspb.Message.computeOneofCase(this, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.Packet.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.Packet.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.Packet} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.Packet.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        opcode: jspb.Message.getFieldWithDefault(msg, 1, 0),
        sequencenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
        targetplayer: jspb.Message.getFieldWithDefault(msg, 3, 0),
        targetgroup: jspb.Message.getFieldWithDefault(msg, 4, 0),
        sender: jspb.Message.getFieldWithDefault(msg, 5, 0),
        reliable: jspb.Message.getFieldWithDefault(msg, 6, false),
        clientversion: jspb.Message.getFieldWithDefault(msg, 7, 0),
        payload: msg.getPayload_asB64(),
        login: (f = msg.getLogin()) && proto_CoreCommands_pb.LoginCommand.toObject(includeInstance, f),
        loginresult: (f = msg.getLoginresult()) && proto_CoreCommands_pb.LoginResult.toObject(includeInstance, f),
        ping: (f = msg.getPing()) && proto_CoreCommands_pb.PingCommand.toObject(includeInstance, f),
        pingresult: (f = msg.getPingresult()) && proto_CoreCommands_pb.PingResult.toObject(includeInstance, f),
        verifyidentity: (f = msg.getVerifyidentity()) && proto_CoreCommands_pb.VerifyIdentityCommand.toObject(includeInstance, f),
        verifyidentityresult:
          (f = msg.getVerifyidentityresult()) && proto_CoreCommands_pb.VerifyIdentityResult.toObject(includeInstance, f),
        udpconnect: (f = msg.getUdpconnect()) && proto_CoreCommands_pb.UDPConnectMessage.toObject(includeInstance, f),
        playerconnect: (f = msg.getPlayerconnect()) && proto_CoreCommands_pb.PlayerConnectMessage.toObject(includeInstance, f),
        playerdisconnect: (f = msg.getPlayerdisconnect()) && proto_CoreCommands_pb.PlayerDisconnectMessage.toObject(includeInstance, f),
        joingroup: (f = msg.getJoingroup()) && proto_GroupMessages_pb.JoinGroup.toObject(includeInstance, f),
        leavegroup: (f = msg.getLeavegroup()) && proto_GroupMessages_pb.LeaveGroup.toObject(includeInstance, f),
        requestgroupmembership:
          (f = msg.getRequestgroupmembership()) && proto_GroupMessages_pb.RequestGroupMembership.toObject(includeInstance, f),
        groupmembershipupdate:
          (f = msg.getGroupmembershipupdate()) && proto_GroupMessages_pb.GroupMembershipUpdate.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.Packet}
 */
proto.com.gamelift.rt.proto.Packet.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.Packet();
  return proto.com.gamelift.rt.proto.Packet.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.Packet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.Packet}
 */
proto.com.gamelift.rt.proto.Packet.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32());
        msg.setOpcode(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSequencenumber(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTargetplayer(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setTargetgroup(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setSender(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setReliable(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setClientversion(value);
        break;
      case 15:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setPayload(value);
        break;
      case 30:
        var value = new proto_CoreCommands_pb.LoginCommand();
        reader.readMessage(value, proto_CoreCommands_pb.LoginCommand.deserializeBinaryFromReader);
        msg.setLogin(value);
        break;
      case 31:
        var value = new proto_CoreCommands_pb.LoginResult();
        reader.readMessage(value, proto_CoreCommands_pb.LoginResult.deserializeBinaryFromReader);
        msg.setLoginresult(value);
        break;
      case 32:
        var value = new proto_CoreCommands_pb.PingCommand();
        reader.readMessage(value, proto_CoreCommands_pb.PingCommand.deserializeBinaryFromReader);
        msg.setPing(value);
        break;
      case 33:
        var value = new proto_CoreCommands_pb.PingResult();
        reader.readMessage(value, proto_CoreCommands_pb.PingResult.deserializeBinaryFromReader);
        msg.setPingresult(value);
        break;
      case 37:
        var value = new proto_CoreCommands_pb.VerifyIdentityCommand();
        reader.readMessage(value, proto_CoreCommands_pb.VerifyIdentityCommand.deserializeBinaryFromReader);
        msg.setVerifyidentity(value);
        break;
      case 38:
        var value = new proto_CoreCommands_pb.VerifyIdentityResult();
        reader.readMessage(value, proto_CoreCommands_pb.VerifyIdentityResult.deserializeBinaryFromReader);
        msg.setVerifyidentityresult(value);
        break;
      case 34:
        var value = new proto_CoreCommands_pb.UDPConnectMessage();
        reader.readMessage(value, proto_CoreCommands_pb.UDPConnectMessage.deserializeBinaryFromReader);
        msg.setUdpconnect(value);
        break;
      case 35:
        var value = new proto_CoreCommands_pb.PlayerConnectMessage();
        reader.readMessage(value, proto_CoreCommands_pb.PlayerConnectMessage.deserializeBinaryFromReader);
        msg.setPlayerconnect(value);
        break;
      case 36:
        var value = new proto_CoreCommands_pb.PlayerDisconnectMessage();
        reader.readMessage(value, proto_CoreCommands_pb.PlayerDisconnectMessage.deserializeBinaryFromReader);
        msg.setPlayerdisconnect(value);
        break;
      case 40:
        var value = new proto_GroupMessages_pb.JoinGroup();
        reader.readMessage(value, proto_GroupMessages_pb.JoinGroup.deserializeBinaryFromReader);
        msg.setJoingroup(value);
        break;
      case 41:
        var value = new proto_GroupMessages_pb.LeaveGroup();
        reader.readMessage(value, proto_GroupMessages_pb.LeaveGroup.deserializeBinaryFromReader);
        msg.setLeavegroup(value);
        break;
      case 42:
        var value = new proto_GroupMessages_pb.RequestGroupMembership();
        reader.readMessage(value, proto_GroupMessages_pb.RequestGroupMembership.deserializeBinaryFromReader);
        msg.setRequestgroupmembership(value);
        break;
      case 43:
        var value = new proto_GroupMessages_pb.GroupMembershipUpdate();
        reader.readMessage(value, proto_GroupMessages_pb.GroupMembershipUpdate.deserializeBinaryFromReader);
        msg.setGroupmembershipupdate(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.Packet.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.Packet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.Packet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.Packet.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getOpcode();
  if (f !== 0) {
    writer.writeSint32(1, f);
  }
  f = message.getSequencenumber();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
  f = message.getTargetplayer();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getTargetgroup();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getSender();
  if (f !== 0) {
    writer.writeInt32(5, f);
  }
  f = message.getReliable();
  if (f) {
    writer.writeBool(6, f);
  }
  f = message.getClientversion();
  if (f !== 0) {
    writer.writeInt32(7, f);
  }
  f = message.getPayload_asU8();
  if (f.length > 0) {
    writer.writeBytes(15, f);
  }
  f = message.getLogin();
  if (f != null) {
    writer.writeMessage(30, f, proto_CoreCommands_pb.LoginCommand.serializeBinaryToWriter);
  }
  f = message.getLoginresult();
  if (f != null) {
    writer.writeMessage(31, f, proto_CoreCommands_pb.LoginResult.serializeBinaryToWriter);
  }
  f = message.getPing();
  if (f != null) {
    writer.writeMessage(32, f, proto_CoreCommands_pb.PingCommand.serializeBinaryToWriter);
  }
  f = message.getPingresult();
  if (f != null) {
    writer.writeMessage(33, f, proto_CoreCommands_pb.PingResult.serializeBinaryToWriter);
  }
  f = message.getVerifyidentity();
  if (f != null) {
    writer.writeMessage(37, f, proto_CoreCommands_pb.VerifyIdentityCommand.serializeBinaryToWriter);
  }
  f = message.getVerifyidentityresult();
  if (f != null) {
    writer.writeMessage(38, f, proto_CoreCommands_pb.VerifyIdentityResult.serializeBinaryToWriter);
  }
  f = message.getUdpconnect();
  if (f != null) {
    writer.writeMessage(34, f, proto_CoreCommands_pb.UDPConnectMessage.serializeBinaryToWriter);
  }
  f = message.getPlayerconnect();
  if (f != null) {
    writer.writeMessage(35, f, proto_CoreCommands_pb.PlayerConnectMessage.serializeBinaryToWriter);
  }
  f = message.getPlayerdisconnect();
  if (f != null) {
    writer.writeMessage(36, f, proto_CoreCommands_pb.PlayerDisconnectMessage.serializeBinaryToWriter);
  }
  f = message.getJoingroup();
  if (f != null) {
    writer.writeMessage(40, f, proto_GroupMessages_pb.JoinGroup.serializeBinaryToWriter);
  }
  f = message.getLeavegroup();
  if (f != null) {
    writer.writeMessage(41, f, proto_GroupMessages_pb.LeaveGroup.serializeBinaryToWriter);
  }
  f = message.getRequestgroupmembership();
  if (f != null) {
    writer.writeMessage(42, f, proto_GroupMessages_pb.RequestGroupMembership.serializeBinaryToWriter);
  }
  f = message.getGroupmembershipupdate();
  if (f != null) {
    writer.writeMessage(43, f, proto_GroupMessages_pb.GroupMembershipUpdate.serializeBinaryToWriter);
  }
};

/**
 * optional sint32 opCode = 1;
 * @return {number}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getOpcode = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.Packet.prototype.setOpcode = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional int32 sequenceNumber = 2;
 * @return {number}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getSequencenumber = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.Packet.prototype.setSequencenumber = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional int32 targetPlayer = 3;
 * @return {number}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getTargetplayer = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.Packet.prototype.setTargetplayer = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 targetGroup = 4;
 * @return {number}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getTargetgroup = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.Packet.prototype.setTargetgroup = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional int32 sender = 5;
 * @return {number}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getSender = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.Packet.prototype.setSender = function (value) {
  jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional bool reliable = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getReliable = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 6, false));
};

/** @param {boolean} value */
proto.com.gamelift.rt.proto.Packet.prototype.setReliable = function (value) {
  jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * optional int32 clientVersion = 7;
 * @return {number}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getClientversion = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.Packet.prototype.setClientversion = function (value) {
  jspb.Message.setProto3IntField(this, 7, value);
};

/**
 * optional bytes payload = 15;
 * @return {!(string|Uint8Array)}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getPayload = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ''));
};

/**
 * optional bytes payload = 15;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getPayload_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getPayload()));
};

/**
 * optional bytes payload = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getPayload_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getPayload()));
};

/** @param {!(string|Uint8Array)} value */
proto.com.gamelift.rt.proto.Packet.prototype.setPayload = function (value) {
  jspb.Message.setProto3BytesField(this, 15, value);
};

/**
 * optional LoginCommand login = 30;
 * @return {?proto.com.gamelift.rt.proto.LoginCommand}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getLogin = function () {
  return /** @type{?proto.com.gamelift.rt.proto.LoginCommand} */ (
    jspb.Message.getWrapperField(this, proto_CoreCommands_pb.LoginCommand, 30)
  );
};

/** @param {?proto.com.gamelift.rt.proto.LoginCommand|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setLogin = function (value) {
  jspb.Message.setOneofWrapperField(this, 30, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearLogin = function () {
  this.setLogin(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasLogin = function () {
  return jspb.Message.getField(this, 30) != null;
};

/**
 * optional LoginResult loginResult = 31;
 * @return {?proto.com.gamelift.rt.proto.LoginResult}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getLoginresult = function () {
  return /** @type{?proto.com.gamelift.rt.proto.LoginResult} */ (jspb.Message.getWrapperField(this, proto_CoreCommands_pb.LoginResult, 31));
};

/** @param {?proto.com.gamelift.rt.proto.LoginResult|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setLoginresult = function (value) {
  jspb.Message.setOneofWrapperField(this, 31, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearLoginresult = function () {
  this.setLoginresult(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasLoginresult = function () {
  return jspb.Message.getField(this, 31) != null;
};

/**
 * optional PingCommand ping = 32;
 * @return {?proto.com.gamelift.rt.proto.PingCommand}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getPing = function () {
  return /** @type{?proto.com.gamelift.rt.proto.PingCommand} */ (jspb.Message.getWrapperField(this, proto_CoreCommands_pb.PingCommand, 32));
};

/** @param {?proto.com.gamelift.rt.proto.PingCommand|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setPing = function (value) {
  jspb.Message.setOneofWrapperField(this, 32, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearPing = function () {
  this.setPing(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasPing = function () {
  return jspb.Message.getField(this, 32) != null;
};

/**
 * optional PingResult pingResult = 33;
 * @return {?proto.com.gamelift.rt.proto.PingResult}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getPingresult = function () {
  return /** @type{?proto.com.gamelift.rt.proto.PingResult} */ (jspb.Message.getWrapperField(this, proto_CoreCommands_pb.PingResult, 33));
};

/** @param {?proto.com.gamelift.rt.proto.PingResult|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setPingresult = function (value) {
  jspb.Message.setOneofWrapperField(this, 33, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearPingresult = function () {
  this.setPingresult(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasPingresult = function () {
  return jspb.Message.getField(this, 33) != null;
};

/**
 * optional VerifyIdentityCommand verifyIdentity = 37;
 * @return {?proto.com.gamelift.rt.proto.VerifyIdentityCommand}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getVerifyidentity = function () {
  return /** @type{?proto.com.gamelift.rt.proto.VerifyIdentityCommand} */ (
    jspb.Message.getWrapperField(this, proto_CoreCommands_pb.VerifyIdentityCommand, 37)
  );
};

/** @param {?proto.com.gamelift.rt.proto.VerifyIdentityCommand|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setVerifyidentity = function (value) {
  jspb.Message.setOneofWrapperField(this, 37, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearVerifyidentity = function () {
  this.setVerifyidentity(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasVerifyidentity = function () {
  return jspb.Message.getField(this, 37) != null;
};

/**
 * optional VerifyIdentityResult verifyIdentityResult = 38;
 * @return {?proto.com.gamelift.rt.proto.VerifyIdentityResult}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getVerifyidentityresult = function () {
  return /** @type{?proto.com.gamelift.rt.proto.VerifyIdentityResult} */ (
    jspb.Message.getWrapperField(this, proto_CoreCommands_pb.VerifyIdentityResult, 38)
  );
};

/** @param {?proto.com.gamelift.rt.proto.VerifyIdentityResult|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setVerifyidentityresult = function (value) {
  jspb.Message.setOneofWrapperField(this, 38, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearVerifyidentityresult = function () {
  this.setVerifyidentityresult(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasVerifyidentityresult = function () {
  return jspb.Message.getField(this, 38) != null;
};

/**
 * optional UDPConnectMessage udpConnect = 34;
 * @return {?proto.com.gamelift.rt.proto.UDPConnectMessage}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getUdpconnect = function () {
  return /** @type{?proto.com.gamelift.rt.proto.UDPConnectMessage} */ (
    jspb.Message.getWrapperField(this, proto_CoreCommands_pb.UDPConnectMessage, 34)
  );
};

/** @param {?proto.com.gamelift.rt.proto.UDPConnectMessage|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setUdpconnect = function (value) {
  jspb.Message.setOneofWrapperField(this, 34, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearUdpconnect = function () {
  this.setUdpconnect(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasUdpconnect = function () {
  return jspb.Message.getField(this, 34) != null;
};

/**
 * optional PlayerConnectMessage playerConnect = 35;
 * @return {?proto.com.gamelift.rt.proto.PlayerConnectMessage}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getPlayerconnect = function () {
  return /** @type{?proto.com.gamelift.rt.proto.PlayerConnectMessage} */ (
    jspb.Message.getWrapperField(this, proto_CoreCommands_pb.PlayerConnectMessage, 35)
  );
};

/** @param {?proto.com.gamelift.rt.proto.PlayerConnectMessage|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setPlayerconnect = function (value) {
  jspb.Message.setOneofWrapperField(this, 35, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearPlayerconnect = function () {
  this.setPlayerconnect(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasPlayerconnect = function () {
  return jspb.Message.getField(this, 35) != null;
};

/**
 * optional PlayerDisconnectMessage playerDisconnect = 36;
 * @return {?proto.com.gamelift.rt.proto.PlayerDisconnectMessage}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getPlayerdisconnect = function () {
  return /** @type{?proto.com.gamelift.rt.proto.PlayerDisconnectMessage} */ (
    jspb.Message.getWrapperField(this, proto_CoreCommands_pb.PlayerDisconnectMessage, 36)
  );
};

/** @param {?proto.com.gamelift.rt.proto.PlayerDisconnectMessage|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setPlayerdisconnect = function (value) {
  jspb.Message.setOneofWrapperField(this, 36, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearPlayerdisconnect = function () {
  this.setPlayerdisconnect(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasPlayerdisconnect = function () {
  return jspb.Message.getField(this, 36) != null;
};

/**
 * optional JoinGroup joinGroup = 40;
 * @return {?proto.com.gamelift.rt.proto.JoinGroup}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getJoingroup = function () {
  return /** @type{?proto.com.gamelift.rt.proto.JoinGroup} */ (jspb.Message.getWrapperField(this, proto_GroupMessages_pb.JoinGroup, 40));
};

/** @param {?proto.com.gamelift.rt.proto.JoinGroup|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setJoingroup = function (value) {
  jspb.Message.setOneofWrapperField(this, 40, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearJoingroup = function () {
  this.setJoingroup(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasJoingroup = function () {
  return jspb.Message.getField(this, 40) != null;
};

/**
 * optional LeaveGroup leaveGroup = 41;
 * @return {?proto.com.gamelift.rt.proto.LeaveGroup}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getLeavegroup = function () {
  return /** @type{?proto.com.gamelift.rt.proto.LeaveGroup} */ (jspb.Message.getWrapperField(this, proto_GroupMessages_pb.LeaveGroup, 41));
};

/** @param {?proto.com.gamelift.rt.proto.LeaveGroup|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setLeavegroup = function (value) {
  jspb.Message.setOneofWrapperField(this, 41, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearLeavegroup = function () {
  this.setLeavegroup(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasLeavegroup = function () {
  return jspb.Message.getField(this, 41) != null;
};

/**
 * optional RequestGroupMembership requestGroupMembership = 42;
 * @return {?proto.com.gamelift.rt.proto.RequestGroupMembership}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getRequestgroupmembership = function () {
  return /** @type{?proto.com.gamelift.rt.proto.RequestGroupMembership} */ (
    jspb.Message.getWrapperField(this, proto_GroupMessages_pb.RequestGroupMembership, 42)
  );
};

/** @param {?proto.com.gamelift.rt.proto.RequestGroupMembership|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setRequestgroupmembership = function (value) {
  jspb.Message.setOneofWrapperField(this, 42, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearRequestgroupmembership = function () {
  this.setRequestgroupmembership(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasRequestgroupmembership = function () {
  return jspb.Message.getField(this, 42) != null;
};

/**
 * optional GroupMembershipUpdate groupMembershipUpdate = 43;
 * @return {?proto.com.gamelift.rt.proto.GroupMembershipUpdate}
 */
proto.com.gamelift.rt.proto.Packet.prototype.getGroupmembershipupdate = function () {
  return /** @type{?proto.com.gamelift.rt.proto.GroupMembershipUpdate} */ (
    jspb.Message.getWrapperField(this, proto_GroupMessages_pb.GroupMembershipUpdate, 43)
  );
};

/** @param {?proto.com.gamelift.rt.proto.GroupMembershipUpdate|undefined} value */
proto.com.gamelift.rt.proto.Packet.prototype.setGroupmembershipupdate = function (value) {
  jspb.Message.setOneofWrapperField(this, 43, proto.com.gamelift.rt.proto.Packet.oneofGroups_[0], value);
};

proto.com.gamelift.rt.proto.Packet.prototype.clearGroupmembershipupdate = function () {
  this.setGroupmembershipupdate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.com.gamelift.rt.proto.Packet.prototype.hasGroupmembershipupdate = function () {
  return jspb.Message.getField(this, 43) != null;
};

// goog.object.extend(exports, proto.com.gamelift.rt.proto);
// Add custom named exports...
export const Packet = proto.com.gamelift.rt.proto.Packet;
