import React, { createContext, ReactNode, useContext, useState } from 'react';

type AuthContextType = {
    isAuthenticated: boolean;
    currentUser: string; //not sure how this will work yet
    checkAuth: () => Promise<boolean>;
    login: () => Promise<boolean>;
    logout: () => void;
    getProfile: () => void;
};

type AuthProviderProps = {
    children: ReactNode;
};

const AuthContext = createContext<AuthContextType>(null!);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useState('');

    const login = (): Promise<boolean> => {
        return new Promise((resolve) => {
            setIsAuthenticated(true);
            setCurrentUser('test');
            setTimeout(() => {
                resolve(true);
            }, 2000);
        });
    };

    const logout = () => {
        setIsAuthenticated(false);
        setCurrentUser('');
    };

    const checkAuth = (): Promise<boolean> => {
        return new Promise((resolve) => {
            resolve(isAuthenticated);
        });
    };

    const getProfile = () => {
        console.log('getting profile');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, currentUser, checkAuth, login, logout, getProfile }}>
            {children}
        </AuthContext.Provider>
    );
};
