"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginResult = void 0;
const RTMessage_1 = require("../classes/RTMessage");
const types_1 = require("@repo/types");
class LoginResult extends RTMessage_1.RTMessage {
    success;
    connectToken;
    reconnectToken;
    constructor(peerId, success, connectToken, reconnectToken, payload) {
        super(types_1.OP_CODE.LOGIN_RESULT);
        this.success = success;
        this.connectToken = connectToken;
        this.reconnectToken = reconnectToken;
        this.targetPlayer = peerId;
        this.payloadBinary = payload;
    }
}
exports.LoginResult = LoginResult;
