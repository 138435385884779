import React from 'react';
import { cn } from '../utils';

interface SkeletonProps {
    className?: string;
}

export const Skeleton: React.FC<SkeletonProps> = ({ className }: SkeletonProps) => {
    return <div className={cn('size-full animate-pulse rounded bg-gray-200', className)}></div>;
};
