"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomState = void 0;
var RoomState;
(function (RoomState) {
    // eslint-disable-next-line no-unused-vars
    RoomState[RoomState["CHARACTER_SELECT"] = 0] = "CHARACTER_SELECT";
    // eslint-disable-next-line no-unused-vars
    RoomState[RoomState["GAME"] = 1] = "GAME";
    // eslint-disable-next-line no-unused-vars
    RoomState[RoomState["GAME_OVER"] = 2] = "GAME_OVER";
    // eslint-disable-next-line no-unused-vars
    RoomState[RoomState["RESULTS"] = 3] = "RESULTS";
})(RoomState || (exports.RoomState = RoomState = {}));
