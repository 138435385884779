import { TwoColumnGrid } from './TwoColumnGrid';
import { Spinner } from './Spinner';
import { LogoSection } from './LogoSection';
import { WoodenBackground } from './WoodenBackground';

export const LoaderOverlay = () => {
    return (
        <div className="fixed left-0 top-0 z-10 h-screen w-full" data-testid="loader-overlay">
            <TwoColumnGrid leftColumnSize="50%" rightColumnSize="50%" height="100dvh">
                <LogoSection />

                <div className="relative">
                    <div className="absolute left-0 top-0 z-[11] flex size-full items-center justify-center">
                        <Spinner />
                    </div>

                    <WoodenBackground />

                    <div className="absolute left-0 top-0 z-[-1] size-full bg-black/30"></div>
                </div>
            </TwoColumnGrid>
        </div>
    );
};
