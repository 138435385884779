import { SoundKey, sounds } from '../data';
import { Howler } from 'howler';

export default class AudioService {
    private static _muted = false;

    public static isMuted() {
        return AudioService._muted;
    }

    public static play(key: SoundKey) {
        const sound = sounds[key];
        return sound.play();
    }

    public static stop(key: SoundKey) {
        const sound = sounds[key];
        sound.stop();
        return;
    }

    public static pause(key: SoundKey) {
        const sound = sounds[key];
        sound.pause();
        return () => sound.play();
    }

    public static resume(key: SoundKey) {
        const sound = sounds[key];
        return sound.play();
    }

    public static setVolume(key: SoundKey, volume: number) {
        const sound = sounds[key];
        return sound.volume(volume);
    }

    public static muteAll() {
        AudioService._muted = true;
        return Howler.mute(true);
    }

    public static unmuteAll() {
        AudioService._muted = false;
        return Howler.mute(false);
    }

    public static setVolumeAll(volume: number) {
        return Howler.volume(volume);
    }
}
