/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

import * as jspb from 'google-protobuf';
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.com.gamelift.rt.proto.LoginCommand', null, global);
goog.exportSymbol('proto.com.gamelift.rt.proto.LoginResult', null, global);
goog.exportSymbol('proto.com.gamelift.rt.proto.PingCommand', null, global);
goog.exportSymbol('proto.com.gamelift.rt.proto.PingResult', null, global);
goog.exportSymbol('proto.com.gamelift.rt.proto.PlayerConnectMessage', null, global);
goog.exportSymbol('proto.com.gamelift.rt.proto.PlayerDisconnectMessage', null, global);
goog.exportSymbol('proto.com.gamelift.rt.proto.UDPConnectMessage', null, global);
goog.exportSymbol('proto.com.gamelift.rt.proto.VerifyIdentityCommand', null, global);
goog.exportSymbol('proto.com.gamelift.rt.proto.VerifyIdentityResult', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.LoginCommand = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.LoginCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.LoginCommand.displayName = 'proto.com.gamelift.rt.proto.LoginCommand';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.LoginCommand.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.LoginCommand.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.LoginCommand} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.LoginCommand.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        playersessionid: jspb.Message.getFieldWithDefault(msg, 1, ''),
        clientversion: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.LoginCommand}
 */
proto.com.gamelift.rt.proto.LoginCommand.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.LoginCommand();
  return proto.com.gamelift.rt.proto.LoginCommand.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.LoginCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.LoginCommand}
 */
proto.com.gamelift.rt.proto.LoginCommand.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setPlayersessionid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setClientversion(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.LoginCommand.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.LoginCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.LoginCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.LoginCommand.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPlayersessionid();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getClientversion();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional string playerSessionId = 1;
 * @return {string}
 */
proto.com.gamelift.rt.proto.LoginCommand.prototype.getPlayersessionid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/** @param {string} value */
proto.com.gamelift.rt.proto.LoginCommand.prototype.setPlayersessionid = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional int32 clientVersion = 2;
 * @return {number}
 */
proto.com.gamelift.rt.proto.LoginCommand.prototype.getClientversion = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.LoginCommand.prototype.setClientversion = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.LoginResult = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.LoginResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.LoginResult.displayName = 'proto.com.gamelift.rt.proto.LoginResult';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.LoginResult.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.LoginResult.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.LoginResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.LoginResult.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        success: jspb.Message.getFieldWithDefault(msg, 1, false),
        reconnecttoken: jspb.Message.getFieldWithDefault(msg, 2, ''),
        peerid: jspb.Message.getFieldWithDefault(msg, 3, 0),
        fastport: jspb.Message.getFieldWithDefault(msg, 4, 0),
        cacert: msg.getCacert_asB64(),
        connecttoken: jspb.Message.getFieldWithDefault(msg, 6, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.LoginResult}
 */
proto.com.gamelift.rt.proto.LoginResult.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.LoginResult();
  return proto.com.gamelift.rt.proto.LoginResult.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.LoginResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.LoginResult}
 */
proto.com.gamelift.rt.proto.LoginResult.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setReconnecttoken(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPeerid(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setFastport(value);
        break;
      case 5:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setCacert(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setConnecttoken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.LoginResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.LoginResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.LoginResult.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getReconnecttoken();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getPeerid();
  if (f !== 0) {
    writer.writeInt32(3, f);
  }
  f = message.getFastport();
  if (f !== 0) {
    writer.writeInt32(4, f);
  }
  f = message.getCacert_asU8();
  if (f.length > 0) {
    writer.writeBytes(5, f);
  }
  f = message.getConnecttoken();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.getSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.com.gamelift.rt.proto.LoginResult.prototype.setSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional string reconnectToken = 2;
 * @return {string}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.getReconnecttoken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/** @param {string} value */
proto.com.gamelift.rt.proto.LoginResult.prototype.setReconnecttoken = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional int32 peerId = 3;
 * @return {number}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.getPeerid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.LoginResult.prototype.setPeerid = function (value) {
  jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional int32 fastPort = 4;
 * @return {number}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.getFastport = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.LoginResult.prototype.setFastport = function (value) {
  jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional bytes caCert = 5;
 * @return {!(string|Uint8Array)}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.getCacert = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * optional bytes caCert = 5;
 * This is a type-conversion wrapper around `getCacert()`
 * @return {string}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.getCacert_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getCacert()));
};

/**
 * optional bytes caCert = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCacert()`
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.getCacert_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getCacert()));
};

/** @param {!(string|Uint8Array)} value */
proto.com.gamelift.rt.proto.LoginResult.prototype.setCacert = function (value) {
  jspb.Message.setProto3BytesField(this, 5, value);
};

/**
 * optional string connectToken = 6;
 * @return {string}
 */
proto.com.gamelift.rt.proto.LoginResult.prototype.getConnecttoken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/** @param {string} value */
proto.com.gamelift.rt.proto.LoginResult.prototype.setConnecttoken = function (value) {
  jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.PingCommand = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.PingCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.PingCommand.displayName = 'proto.com.gamelift.rt.proto.PingCommand';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.PingCommand.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.PingCommand.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.PingCommand} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.PingCommand.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.PingCommand}
 */
proto.com.gamelift.rt.proto.PingCommand.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.PingCommand();
  return proto.com.gamelift.rt.proto.PingCommand.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.PingCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.PingCommand}
 */
proto.com.gamelift.rt.proto.PingCommand.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.PingCommand.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.PingCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.PingCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.PingCommand.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.PingResult = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.PingResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.PingResult.displayName = 'proto.com.gamelift.rt.proto.PingResult';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.PingResult.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.PingResult.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.PingResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.PingResult.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.PingResult}
 */
proto.com.gamelift.rt.proto.PingResult.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.PingResult();
  return proto.com.gamelift.rt.proto.PingResult.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.PingResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.PingResult}
 */
proto.com.gamelift.rt.proto.PingResult.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.PingResult.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.PingResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.PingResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.PingResult.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.VerifyIdentityCommand = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.VerifyIdentityCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.VerifyIdentityCommand.displayName = 'proto.com.gamelift.rt.proto.VerifyIdentityCommand';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.VerifyIdentityCommand.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.VerifyIdentityCommand.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.VerifyIdentityCommand} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.VerifyIdentityCommand.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        peerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
        connecttoken: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.VerifyIdentityCommand}
 */
proto.com.gamelift.rt.proto.VerifyIdentityCommand.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.VerifyIdentityCommand();
  return proto.com.gamelift.rt.proto.VerifyIdentityCommand.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.VerifyIdentityCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.VerifyIdentityCommand}
 */
proto.com.gamelift.rt.proto.VerifyIdentityCommand.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPeerid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setConnecttoken(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.VerifyIdentityCommand.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.VerifyIdentityCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.VerifyIdentityCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.VerifyIdentityCommand.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPeerid();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
  f = message.getConnecttoken();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional int32 peerId = 1;
 * @return {number}
 */
proto.com.gamelift.rt.proto.VerifyIdentityCommand.prototype.getPeerid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.VerifyIdentityCommand.prototype.setPeerid = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string connectToken = 2;
 * @return {string}
 */
proto.com.gamelift.rt.proto.VerifyIdentityCommand.prototype.getConnecttoken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/** @param {string} value */
proto.com.gamelift.rt.proto.VerifyIdentityCommand.prototype.setConnecttoken = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.VerifyIdentityResult = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.VerifyIdentityResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.VerifyIdentityResult.displayName = 'proto.com.gamelift.rt.proto.VerifyIdentityResult';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.VerifyIdentityResult.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.VerifyIdentityResult.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.VerifyIdentityResult} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.VerifyIdentityResult.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        success: jspb.Message.getFieldWithDefault(msg, 1, false),
        peerid: jspb.Message.getFieldWithDefault(msg, 2, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.VerifyIdentityResult}
 */
proto.com.gamelift.rt.proto.VerifyIdentityResult.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.VerifyIdentityResult();
  return proto.com.gamelift.rt.proto.VerifyIdentityResult.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.VerifyIdentityResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.VerifyIdentityResult}
 */
proto.com.gamelift.rt.proto.VerifyIdentityResult.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setSuccess(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPeerid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.VerifyIdentityResult.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.VerifyIdentityResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.VerifyIdentityResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.VerifyIdentityResult.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(1, f);
  }
  f = message.getPeerid();
  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};

/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.com.gamelift.rt.proto.VerifyIdentityResult.prototype.getSuccess = function () {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};

/** @param {boolean} value */
proto.com.gamelift.rt.proto.VerifyIdentityResult.prototype.setSuccess = function (value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};

/**
 * optional int32 peerId = 2;
 * @return {number}
 */
proto.com.gamelift.rt.proto.VerifyIdentityResult.prototype.getPeerid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.VerifyIdentityResult.prototype.setPeerid = function (value) {
  jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.UDPConnectMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.UDPConnectMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.UDPConnectMessage.displayName = 'proto.com.gamelift.rt.proto.UDPConnectMessage';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.UDPConnectMessage.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.UDPConnectMessage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.UDPConnectMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.UDPConnectMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.UDPConnectMessage}
 */
proto.com.gamelift.rt.proto.UDPConnectMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.UDPConnectMessage();
  return proto.com.gamelift.rt.proto.UDPConnectMessage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.UDPConnectMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.UDPConnectMessage}
 */
proto.com.gamelift.rt.proto.UDPConnectMessage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.UDPConnectMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.UDPConnectMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.UDPConnectMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.UDPConnectMessage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.PlayerConnectMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.PlayerConnectMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.PlayerConnectMessage.displayName = 'proto.com.gamelift.rt.proto.PlayerConnectMessage';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.PlayerConnectMessage.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.PlayerConnectMessage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.PlayerConnectMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.PlayerConnectMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        peerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.PlayerConnectMessage}
 */
proto.com.gamelift.rt.proto.PlayerConnectMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.PlayerConnectMessage();
  return proto.com.gamelift.rt.proto.PlayerConnectMessage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.PlayerConnectMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.PlayerConnectMessage}
 */
proto.com.gamelift.rt.proto.PlayerConnectMessage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPeerid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.PlayerConnectMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.PlayerConnectMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.PlayerConnectMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.PlayerConnectMessage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPeerid();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
};

/**
 * optional int32 peerId = 1;
 * @return {number}
 */
proto.com.gamelift.rt.proto.PlayerConnectMessage.prototype.getPeerid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.PlayerConnectMessage.prototype.setPeerid = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.gamelift.rt.proto.PlayerDisconnectMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.gamelift.rt.proto.PlayerDisconnectMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.com.gamelift.rt.proto.PlayerDisconnectMessage.displayName = 'proto.com.gamelift.rt.proto.PlayerDisconnectMessage';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.com.gamelift.rt.proto.PlayerDisconnectMessage.prototype.toObject = function (opt_includeInstance) {
    return proto.com.gamelift.rt.proto.PlayerDisconnectMessage.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.com.gamelift.rt.proto.PlayerDisconnectMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.com.gamelift.rt.proto.PlayerDisconnectMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        peerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.gamelift.rt.proto.PlayerDisconnectMessage}
 */
proto.com.gamelift.rt.proto.PlayerDisconnectMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.gamelift.rt.proto.PlayerDisconnectMessage();
  return proto.com.gamelift.rt.proto.PlayerDisconnectMessage.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.gamelift.rt.proto.PlayerDisconnectMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.gamelift.rt.proto.PlayerDisconnectMessage}
 */
proto.com.gamelift.rt.proto.PlayerDisconnectMessage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readInt32());
        msg.setPeerid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.gamelift.rt.proto.PlayerDisconnectMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.com.gamelift.rt.proto.PlayerDisconnectMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.gamelift.rt.proto.PlayerDisconnectMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.gamelift.rt.proto.PlayerDisconnectMessage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPeerid();
  if (f !== 0) {
    writer.writeInt32(1, f);
  }
};

/**
 * optional int32 peerId = 1;
 * @return {number}
 */
proto.com.gamelift.rt.proto.PlayerDisconnectMessage.prototype.getPeerid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/** @param {number} value */
proto.com.gamelift.rt.proto.PlayerDisconnectMessage.prototype.setPeerid = function (value) {
  jspb.Message.setProto3IntField(this, 1, value);
};

// goog.object.extend(exports, proto.com.gamelift.rt.proto);
// Add custom named exports...
export const LoginCommand = proto.com.gamelift.rt.proto.LoginCommand;
export const LoginResult = proto.com.gamelift.rt.proto.LoginResult;
export const PingCommand = proto.com.gamelift.rt.proto.PingCommand;
export const PingResult = proto.com.gamelift.rt.proto.PingResult;
export const VerifyIdentityCommand = proto.com.gamelift.rt.proto.VerifyIdentityCommand;
export const VerifyIdentityResult = proto.com.gamelift.rt.proto.VerifyIdentityResult;
export const UDPConnectMessage = proto.com.gamelift.rt.proto.UDPConnectMessage;
export const PlayerConnectMessage = proto.com.gamelift.rt.proto.PlayerConnectMessage;
export const PlayerDisconnectMessage = proto.com.gamelift.rt.proto.PlayerDisconnectMessage;
